import React from "react";
import BookingLists from "../Molecule/BookingList/BookingList";
import BookingSidebar from "../Molecule/BookingList/BookingSidebar";



const BookingList=()=>{
    return(
<div>
<BookingSidebar/>
<BookingLists/>
</div>
    )
}
export default BookingList;