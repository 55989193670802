import React from "react";
import Form_Button from "../../Custom-Components/Form_Button";
import Form_Input from "../../Custom-Components/Form_Input";
import Form_Select from "../../Custom-Components/Form_Select";
import { apiurl, authCode } from "../../Host";
import State, { useEffect, useState } from "react";
import { FormSelect } from "semantic-ui-react";
import { useSelector } from "react-redux";
import { Create_UserAPI } from "../../API/Login_API";

const AddUserFrom = () => {
  const auth = useSelector((state) => state.auth);
  const [user, setUser] = useState({
    name: "",
    email: "",
    password: "",
    contact: "",
    rollId: "",
    status: "",
    siteId: "",
    managerId: "",
  });

  const status = [
    { id: 1, name: "Active", value: "Active" },
    { id: 2, name: "Deactive", value: "Deactive" },
  ];

  const sites = [
    { id: 1, name: "Travomint-Vaccations", value: 1 },
    { id: 2, name: "ReservationsDeal-Vaccations", value: 2 },
  ];

  const rolls = [
    { id: 1, name: "Admin", value: 1 },
    { id: 2, name: "Manager", value: 2 },
    { id: 3, name: "Agent", value: 3 },
    { id: 4, name: "Product", value: 4 },
  ];

  const rollsForManger = [
    { id: 3, name: "Agent", value: 3 },
    { id: 4, name: "Product", value: 4 },
  ];

  const [activeMangers, setActiveMangers] = useState([]);

  const getManagers = () => {
    var requestOptions = {
      method: "GET",
      redirect: "follow",
    };

    fetch(apiurl + "/getAllManagers/2?authCode=" + authCode, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        console.log(result);
        setActiveMangers(result);
      })
      .catch((error) => console.log("error", error));
  };
  const GetValue = (e) => {
    setUser({ ...user, [e.target.name]: e.target.value });
  };

  console.log("User Details", user);

  const SubmitUser = (e) => {
    e.preventDefault();
    Create_UserAPI(user);
  };

  useEffect(() => {
    getManagers();
  }, []);

  return (
    <div className="form-box">
      <div className="container form-content">
        <h1 className="form-head">
          <i class="fa-solid page-icon fa-users"></i>&nbsp;Add Users
        </h1>
        <form onSubmit={SubmitUser}>
          <div className="row">
            <Form_Select
              label="Select Site"
              name="siteId"
              dropdown={sites}
              id="siteId"
              value={user.siteId}
              onChange={(e) => GetValue(e)}
            />
            <Form_Input
              label="User Name"
              name="name"
              div_class="col-6"
              id="name"
              value={user.name}
              onChange={(e) => GetValue(e)}
            />
            <Form_Input
              label="User Email"
              name="email"
              div_class="col-6"
              id="email"
              value={user.email}
              onChange={(e) => GetValue(e)}
            />
            <Form_Input
              label="User Password"
              name="password"
              div_class="col-6"
              id="password"
              value={user.password}
              onChange={(e) => GetValue(e)}
            />
            <Form_Input
              label="User Contact"
              name="contact"
              div_class="col-6"
              id="contact"
              value={user.contact}
              onChange={(e) => GetValue(e)}
            />
            <Form_Select
              label="Status"
              dropdown={status}
              id="status"
              name="status"
              value={user.status}
              onChange={(e) => GetValue(e)}
            />
            <Form_Select
              label="Roll"
              dropdown={auth.userData.roleId === 1 ? rolls : rollsForManger}
              id="rollId"
              name="rollId"
              value={user.rollId}
              onChange={(e) => GetValue(e)}
            />

            {user.rollId === 1 ? (
              <Form_Select
                label="Manager"
                dropdown={activeMangers}
                id="managerId"
                name="managerId"
                value={user.managerId}
                onChange={(e) => GetValue(e)}
              />
            ) : (
              <Form_Select
                label="Manager"
                dropdown={activeMangers}
                id="managerId"
                name="managerId"
                value={(user.managerId = auth.userData.userId)}
                onChange={(e) => GetValue(e)}
                disabled="true"
              />
            )}

            <Form_Button div_class="col-6" />
          </div>
        </form>
      </div>
    </div>
  );
};

export default AddUserFrom;
