import React from "react";

const Form_Radio = (props) => {
  const{id,name,value,label,onChange}=props
  return (
    <div className="check-list radio">
        <input type="radio" id={id} name={name} value={value} onChange={onChange}></input>
        <label for={id}>{label}</label>
    </div>
  );
};

export default Form_Radio;
