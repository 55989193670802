import React from "react";
import ApiTracking from "../Molecule/Apitracking/ApiTracking";
import ApiTrackingSideBar from "../Molecule/Apitracking/ApiTrackingSideBar";

const Apitracking=()=>{
    return(
<div>
<ApiTrackingSideBar/>
<ApiTracking/>

</div>
    )
}
export default Apitracking;