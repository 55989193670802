import React from "react";

const Form_Button = (props) => {
  const { onClick, getter, div_class, value, className } = props;
  return (
    <div className={`${div_class} form-group`}>
      <input
        value={value}
        type="submit"
        className={`${className} btn  btn-success`}
        onClick={onClick}
        disabled={
          getter === "Alredy Exist!" || getter === "Check Availability"
            ? true
            : false
        }
      />
    </div>
  );
};

export default Form_Button;
