import React from "react";
import DashboardBar from "../Molecule/Dashboard/DashboardSideBar";
import DashboardData from "../Molecule/Dashboard/Dashboard";
import useAuth from "../Hooks/useAuth";

const Dashboard = () => {
  return (
    <div>
      <DashboardBar />
      <DashboardData />
    </div>
  );
};

export default Dashboard;
