import axios from "axios";
import React, { useEffect, useState } from "react";
import { Form, Button } from "react-bootstrap";
import { useNavigate } from "react-router";
import PopupSuccess from "./Atom/Popup/Popup.js";
import side from "../src/Image/side1.jpg";
import { Login_API } from "./API/Login_API.js";
import useAuth from "./Hooks/useAuth.js";
import { host } from "./Host";

function Login() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [Sucess, setSucess] = useState(false);
  const [errormessage, seterrormessage] = useState({});

  const { login } = useAuth();

  const baseUrl = `${host}`;
  const loginValidtion = (email, password) => {
    let err = {};
    let status = false;
    const EMAIL_REGEX =
      /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
    const PASSWORD_REGEX =
      /^(?=.*[a-zA-Z])(?=.*\d)(?=.*[!@#$%^&*()_+])[A-Za-z\d][A-Za-z\d!@#$%^&*()_+]{7,19}$/;
    if (!email) {
      err.email = "Email is Required";
      seterrormessage(err);
      status = true;
    } else if (!EMAIL_REGEX.test(email)) {
      err.email = "Email not valid";
      seterrormessage(err);
      status = true;
    }
    if (!password) {
      err.password = "Password is Required";
      seterrormessage(err);
      status = true;
    } else if (!PASSWORD_REGEX.test(password)) {
      err.password = "Password not valid";
      seterrormessage(err);
      status = true;
    }
    return status;
  };

  const SubmitLogin = (e) => {
    e.preventDefault();
    const err = loginValidtion(email, password);
    if (err) {
      return null;
    } else {
      login(email, password);
    }
  };

  return (
    <main className="main-login">
      {Sucess ? <PopupSuccess /> : ""}

      <Form className="login-form" onSubmit={SubmitLogin}>
        <div className="panel_form">
          <h1 className="login-heading">LOGIN FORM</h1>
          <Form.Group className="mb-3">
            <Form.Control
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              type="email"
              placeholder="Enter email"
              className="input-login"
              required
            />
          </Form.Group>
          <span className="text-red">{errormessage.email}</span>
          <Form.Group className="mb-3">
            <Form.Control
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              type="password"
              placeholder="Password"
              className="input-login"
              required
            />
          </Form.Group>
          <span className="text-red"> {errormessage.password}</span>

          <input type={"submit"} className="btn-submit" />
          <div className="forgot">
            <span>
              Not have account?{" "}
              <a href="#" className="pull-right">
                Forgot password?
              </a>
            </span>
          </div>
        </div>
      </Form>
    </main>
  );
}

export default Login;
