import React from "react";
import AutoCard from "../Molecule/Auto/AutoCard";
import AutoUserSideBar from "../Molecule/AutoUser/AutoUserSideBar";

const Auto=()=>{
    return(
<div>
<AutoUserSideBar/>
<AutoCard/>

</div>
    )
}
export default Auto;