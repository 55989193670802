import React from "react";

function Form_Select(props) {
  const {
    label,
    dropdown,
    id,
    value,
    onChange,
    name,
    divClass,
    disabled,
    onClick,
    required = true,
  } = props;
  // console.log(dropdown)
  return (
    <div className={divClass ? divClass : "col-6 form-group"}>
      <label className="col-3">{label}</label>
      <select
        className="col-3"
        id={id}
        onChange={onChange}
        name={name}
        required={required}
        disabled={disabled}
        onClick={onClick}
      >
        <option value="">-Please Select-</option>
        {dropdown.map((item, i) =>
          value == item.id ? (
            <option
              id={item.id}
              value={item.id}
              name={item.name}
              selected="selected"
            >
              {" "}
              {item.name}
              {item.userName}{" "}
            </option>
          ) : (
            <option id={item.id} value={item.id} name={item.name}>
              {" "}
              {item.name} {item.userName}
            </option>
          )
        )}
      </select>
    </div>
  );
}

export default Form_Select;
