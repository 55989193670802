import React from "react";
import { useSelector } from "react-redux";
import Authuser from "../../Image/authuser.png"
import vacation from "../../Image/vacation.png";
// import customer from "../../Image/customer-support.png"

const DashboardData = () => {
  const Auth = useSelector((state) => state.auth);
  console.log("Auth", Auth);
  return (
    <div className="text-center">
      <div className="form-box welcomebx-index py-0 overflow-hidden">
        <h1 className="mt-5 heading1"> Welcome To Vacation CRM</h1>
        <div className="row align-items-end text-center">
          <div className="col-md-6 align-self-center">
            <div className="d-inline-block">
              <div className=" py-1">
                <div className="dropposition">
                  <img src={Authuser} className="user-logoutmenu-img" />
                </div>
                <div className="mt-2">
                  {/* <h3 className="font-weight-bold text-white">Hi</h3> */}
                  <h3 className="text-white">
                    Hi, {Auth.userData.userName}
                  </h3>{" "}
                  <h3 className="text-white"> Welcome Back! </h3>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-6">
            <img
              src={vacation}
              className="user-customersupport"
              width={"500px"}
              height={"500px"}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default DashboardData;
