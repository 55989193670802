import React from 'react'
import TopBar from '../Atom/TopBar/TopBar'
import SideBar from "../Atom/SideBar/SideBar";
import { useParams } from "react-router";
import ViewProfile from '../Atom/Form/ViewProfile';
const Profile_Page = () => {
    const leadId = useParams();
    return (
        <>
            <TopBar />
            <SideBar />
            <ViewProfile leadId={leadId}/>
        </>

    )
}

export default Profile_Page