import React, { useEffect, useRef, useState } from "react";
import { getValue } from "@mui/system";
import { useSelector } from "react-redux";
import moment from "moment";
import { Update_Booking, download_Details } from "../../../API/Booking_API";
import Form_Input from "../../../Custom-Components/Form_Input";
import Form_Editor from "../../../Custom-Components/Form_Editor";
import Form_Select from "../../../Custom-Components/Form_Select";
import Form_Button from "../../../Custom-Components/Form_Button";

const EditBooking = ({ editBookingData }) => {
  const authUser = useSelector((state) => state.auth);
  const paymentStatus = [
    { id: 1, name: "Success", value: "Success" },
    { id: 2, name: "Pending", value: "Pending" },
    { id: 3, name: "Failed", value: "Failed" },
  ];
  const bookingStatus = [
    { id: "Open", name: "Open", value: "Open" },
    { id: "Follow up", name: "Follow up", value: "Follow up" },
    {
      id: "Payment Follow up",
      name: "Payment Follow up",
      value: "Payment Follow up",
    },
    { id: "Closed", name: "Closed", value: "Closed" },
  ];
  const [editBooking, setEditBooking] = useState(editBookingData);

  const GetContent = (e) => {
    setEditBooking({
      ...editBooking,
      [e.target.id]: e.target.getContent(),
    });
  };

  const GetValue = (e, name) => {
    setEditBooking({ ...editBooking, [name]: e.target.value });
  };

  const downloadBookingdetails = () => {
    download_Details(editBooking);
  };

  const SubmitEditBooking = (e) => {
    e.preventDefault();
    Update_Booking(editBooking.id, editBooking);
  };

  return (
    <div className="form-box">
      <div className="container form-content">
        <h1 className="form-head">
          <i class="fa-solid page-icon fa-ticket"></i>&nbsp;Edit Booking{" "}
          <span className="form-head-product">{editBooking.bookingId}</span>
        </h1>
        <form onSubmit={SubmitEditBooking}>
          <div className="row">
            <Form_Input
              label="Given Name"
              name="givenName"
              div_class="col-3"
              id="givenName"
              value={editBooking.givenName}
              onChange={(e) => GetValue(e, "givenName")}
              disabled
            />
            <Form_Input
              label="Last Name"
              name="lastName"
              div_class="col-3"
              id="lastName"
              value={editBooking.lastName}
              onChange={(e) => GetValue(e, "lastName")}
              disabled
            />
            <Form_Input
              label="D.O.B"
              name="dob"
              div_class="col-3"
              id="dob"
              value={
                editBooking.dob === null
                  ? null
                  : moment(editBooking.dob).format("MMM DD YYYY")
              }
              onChange={(e) => GetValue(e, "dob")}
              disabled
            />
            <Form_Input
              label="Customer Email"
              name="email"
              div_class="col-3"
              id="email"
              value={editBooking.email}
              required={true}
              onChange={(e) => GetValue(e, "email")}
              disabled
            />
            <Form_Input
              label="Phone"
              name="phone"
              div_class="col-3"
              id="phone"
              type="tel"
              value={editBooking.phone}
              onChange={(e) => GetValue(e, "phone")}
              disabled
            />
            <Form_Input
              label="Alternate Phone No"
              name="alternateMobileNo"
              div_class="col-3"
              id="alternateMobileNo"
              type="tel"
              value={editBooking.alternateMobileNo}
              onChange={(e) => GetValue(e, "alternateMobileNo")}
            />
            <Form_Input
              label="Zip Code"
              name="zipCode"
              div_class="col-3"
              id="zipCode"
              value={editBooking.zipCode}
              onChange={(e) => GetValue(e, "zipCode")}
              disabled
            />
            <Form_Input
              label="Booking Option"
              name="bookingOptions"
              div_class="col-3"
              id="bookingOptions"
              value={editBooking.bookingOptions}
              onChange={(e) => GetValue(e, "bookingOptions")}
              disabled
            />
            <Form_Input
              label="Address"
              name="address"
              div_class="col-6"
              id="address"
              value={editBooking.address}
              onChange={(e) => GetValue(e, "address")}
              disabled
            />
            <Form_Input
              label="Select Product Refrence"
              name="productName"
              div_class="col-6"
              id="productName"
              value={editBooking.productName}
              onChange={(e) => GetValue(e, "productName")}
              disabled
            />

            {/* <Auto_Complete
                div_class="col-6"
                // options={products}
                label="Select Product Refrence"
                // setSelectedProducts={setSelectedProducts}
                /> */}

            <Form_Editor
              label="Booking Details"
              div_class="col-12"
              label_class="col-6"
              initaltext={editBooking.bookingDetails}
              id="bookingDetails"
              onChange={(e) => GetContent(e)}
            />

            <Form_Select
              label="Payment Status"
              dropdown={paymentStatus}
              id="paymentStatus"
              name="paymentStatus"
              divClass="col-3 form-group"
              value={editBooking.paymentStatus}
              onChange={(e) => GetValue(e, "paymentStatus")}
            />

            <Form_Select
              label="Booking Status"
              dropdown={bookingStatus}
              id="bookingStatus"
              name="bookingStatus"
              divClass="col-3 form-group"
              value={editBooking.bookingStatus}
              onChange={(e) => GetValue(e, "bookingStatus")}
            />

            <Form_Input
              label="Total Amount"
              name="amount"
              div_class="col-3"
              id="amount"
              value={editBooking.amount}
              onChange={(e) => GetValue(e, "amount")}
            />
            <Form_Input
              label="MCO Amount"
              name="mcoAmount"
              div_class="col-3"
              id="mcoAmount"
              value={editBooking.mcoAmount}
              onChange={(e) => GetValue(e, "mcoAmount")}
            />
            {/* <Form_Input
              label="Agent Comment"
              name="agentComment"
              div_class="col-3 h-100"
              id="agentComment"
              value={editBooking.agentComment}
              onChange={(e) => GetValue(e, "agentComment")}
            /> */}
            <label className="mx-2 text-black mb-2">Agent Comment</label>
            <textarea
              placeholder="Agent Comment"
              name="agentComment"
              id="agentComment"
              value={editBooking.agentComment}
              onChange={(e) => GetValue(e, "agentComment")}
              className="mx-3 col-6"
            ></textarea>
            <Form_Button div_class="col-3 submt" />
          </div>
        </form>
        {authUser.userData.roleId === 1 || authUser.userData.roleId === 3 ? (
          <button
            className="undefined btn  btn-success mt-4 col-3 h-50"
            onClick={downloadBookingdetails}
          >
            Download Booking Details
          </button>
        ) : (
          ""
        )}
      </div>
    </div>
  );
};

export default EditBooking;
