import React from "react";
import TopBar from "../Atom/TopBar/TopBar";
import SideBar from "../Atom/SideBar/SideBar";
import ViewLeadsForm from "../Atom/Form/ViewLeadsForm";

const ViewLeads = () => {
  return (
    <div>
      <TopBar />
      <SideBar />
      <ViewLeadsForm />
    </div>
  );
};

export default ViewLeads;
