import React from "react";

const Form_Button = (props) => {
  const { onClick, getter } = props;
  // console.log("getter",getter);
  return (
    <div className="col-6 form-group">
      <label>&nbsp;</label>
      <input
        type="submit"
        className="btn  btn-success"
        onClick={onClick}
        disabled={getter === "Alredy Exist!" ? true : false}
      />
    </div>
  );
};

export default Form_Button;
