import { isHostComponent } from "@mui/base";
import React, { useEffect, useState } from "react";
import { from } from "rxjs";
import { Package_API } from "../../API/Package_API";
import Form_Button from "../../Custom-Components/Form_Button";
import Form_Input from "../../Custom-Components/Form_Input";
import Form_Select from "../../Custom-Components/Form_Select";
import { apiurl, authCode } from "../../Host";
import Form_Grup_Input from "../../API/Check_Package";
import Form_CheckBox from "../../Custom-Components/Form_CheckBox";

function AddPackageForm() {
  const [packageValues, setCountryValues] = useState({
    packageName: "",
    metaDescription: "",
    metaTitle: "",
    metaKeywords: "",
    site: "",
    countryid: "",
    packageStatus: "",
  });
  console.log("packageValues", JSON.stringify(packageValues));
  const [selectedCountries, setSelectCounrtries] = useState([]);
  const status = [
    { id: 1, name: "Active", value: "Active" },
    { id: 2, name: "Deactive", value: "Deactive" },
  ];
  const sites = [
    { id: 1, name: "Travomint-Vaccations", value: 1 },
    { id: 2, name: "ReservationsDeal-Vaccations", value: 2 },
  ];
  // const countries = [
  //   { id: 1, name: "India", value: 1 },
  //   { id: 2, name: "USA", value: 2 },
  // ];
  const selectCountries = (id, e, name) => {
    if (e.target.checked) {
      selectedCountries.push(id);
      setCountryValues({ ...packageValues, [name]: selectedCountries });
    } else {
      selectedCountries.pop(id);
    }
  };
  const [countries, setCountries] = useState([]);
  const [getter, setter] = useState("Check Availability");

  const GetValue = (e) => {
    setCountryValues({ ...packageValues, [e.target.name]: e.target.value });
  };

  const getCountries = () => {
    var requestOptions = {
      method: "GET",
      redirect: "follow",
    };

    fetch(
      apiurl +
        `/getCountriesBySiteIdCRM/${packageValues.site}?authCode=` +
        authCode,
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        setCountries(result);
      })
      .catch((error) => console.log("error", error));
  };

  const SubmitCountry = async (e) => {
    e.preventDefault();
    const packageApiResult = await Package_API(packageValues);
    console.log("packageApiResult", packageApiResult);
  };

  return (
    <div className="form-box">
      <div className="container form-content">
        <h1 className="form-head">
          <i class="fa-solid page-icon fa-suitcase"></i>&nbsp;Add Packages
        </h1>
        <form onSubmit={SubmitCountry}>
          <div className="row">
            <Form_Select
              label="Select Site"
              name="site"
              dropdown={sites}
              id="sites"
              value={packageValues.site}
              onChange={(e) => GetValue(e)}
              onClick={() => getCountries()}
            />

            <Form_Grup_Input
              label="Package Name"
              name="packageName"
              div_class="col-6"
              id="packageName"
              value={packageValues.packageName}
              onChange={(e) => GetValue(e)}
              getter={getter}
              setter={setter}
            />

            <Form_Input
              label="Meta Description"
              name="metaDescription"
              div_class="col-6"
              id="metaDescription"
              value={packageValues.metaDescription}
              onChange={(e) => GetValue(e)}
            />
            <Form_Input
              label="Meta Tittle"
              name="metaTitle"
              div_class="col-6"
              id="metaTitle"
              value={packageValues.metaTitle}
              onChange={(e) => GetValue(e)}
            />

            <Form_Input
              label="Meta Keywords"
              name="metaKeywords"
              div_class="col-6"
              id="metaKeywords"
              value={packageValues.metaKeywords}
              onChange={(e) => GetValue(e)}
            />
            {/* <div className="col-6">
              <label className="custom">Countries</label>
              {countries.map((item) => {
                return (
                  <Form_CheckBox
                    label={item.name}
                    id={item.id}
                    value={item.id}
                    onChange={(e) => selectCountries(item.id, e, "countryid")}
                    disabled={packageValues.site == "" ? true : false}
                  />
                );
              })}
            </div> */}
            <Form_Select
              label="Select Country"
              name="countryId"
              dropdown={countries}
              id="countryid"
              value={packageValues.countryid}
              onChange={(e) => GetValue(e)}
              disabled={packageValues.site == "" ? true : false}
            />
            <Form_Select
              label="Status"
              dropdown={status}
              id="status"
              name="packageStatus"
              value={packageValues.packageStatus}
              onChange={(e) => GetValue(e)}
            />
            {/* <Form_Input
              type="file"
              label="Package Image"
              name="packageImage"
              div_class="col-6"
              accept="image/*"
            /> */}
            <Form_Button getter={getter} />
          </div>
        </form>
      </div>
    </div>
  );
}

export default AddPackageForm;
