import React, { useContext, useEffect } from "react";
import Login from "./Login";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Dashboard from "./Views/Dashboard";
import Apitracking from "./Views/ApiTracking";
import AutoUser from "./Views/AutoUser";
import BookingList from "./Views/BookingList";
import Auto from "./Views/Auto";
import User from "./Views/Users";
import PaymentDetail from "./Views/PaymentDetail";
import Country from "./Views/Country";
import Packages from "./Views/Packages";
import VaccationType from "./Views/VaccationType";
import Locations from "./Views/Locations";
import Product from "./Views/Product";
import { AuthContext, AuthProvider } from "./API/AuthContextApi";
import useAuth from "./Hooks/useAuth";
import { useSelector } from "react-redux";
import Country_Edit from "./Views/Country_Edit";
import Packages_Edit from "./Views/Packages_Edit";
import Location_Edit from "./Views/Location_Edit";
import VacctionType_Edit from "./Views/VaccationType_Edit";
import Product_Edit from "./Views/Product_Edit";
import ViewProduct from "./Views/ViewProduct";
import Leads from "./Views/AddLeads";
import ViewLeads from "./Views/ViewLeads";
import { useParams } from "react-router";
import Booking from "./Views/Booking";
import ViewAllUsers from "./Molecule/User/ViewAllUsers";
import User_Edit from "./Views/User_Edit";
import Booking_Edit from "./Views/Booking_Edit";
import Profile_Page from "./Views/Profile_Page";
import ViewBookings from "./Views/ViewBookings";
import Booking_View from "./Views/Booking_View";
import AddPayments from "./Views/AddPayments";
import ViewPayments from "./Views/ViewPayments";
import Payment_Edit from "./Views/Payment_Edit";
import Booking_Product from "./Views/Booking_Product";
import Booking_Docs from "./Views/Booking_Docs";
import Product_Images from "./Views/Product_Images";
import WebsiteQueries from "./Views/WebsiteQueries";

function App() {
  const url = useParams();
  const auth = useSelector((state) => state.auth);
  // const roleid = 1
  const roleid = auth.isLoggedIn === false ? "Login" : auth.userData.roleId;

  useEffect(() => {}, [url]);
  return (
    <main>
      <Router>
        <AuthProvider>
          <Routes>
            {auth.isLoggedIn === false ? (
              <Route path="/" element={<Login />} />
            ) : (
              <>
                <Route path="/" element={<Login />} />
                <Route path="/dashboard" element={<Dashboard />} />
                <Route path="/Apitracking" element={<Apitracking />} />
                <Route path="/AutoUser" element={<AutoUser />} />
                <Route path="/list" element={<BookingList />} />
                <Route path="/country" element={<Country />} />
                <Route path="/package" element={<Packages />} />
                <Route path="/vaccationtype" element={<VaccationType />} />
                <Route path="/locations" element={<Locations />} />
                <Route path="/products" element={<Product />} />
                <Route path="/viewProducts" element={<ViewProduct />} />

                <Route path="/bookings" element={<Booking />} />
                <Route path="/bookingList" element={<ViewBookings />} />

                <Route path="/addLeads" element={<Leads />} />
                <Route path="/viewLeads" element={<ViewLeads />} />
                <Route path="/websiteQueries" element={<WebsiteQueries />} />
                <Route path="/viewUsers" element={<ViewAllUsers />} />

                <Route path="/addPayments" element={<AddPayments />} />
                <Route path="/viewPayments" element={<ViewPayments />} />

                <Route path="/users" element={<User />} />

                <Route path="/Auto" element={<Auto />} />
                <Route path="/Auto" element={<Auto />} />

                <Route
                  path="/Country/Edit/:productId/:siteId"
                  element={<Country_Edit />}
                />
                <Route
                  path="/Packages/Edit/:productId/:siteId"
                  element={<Packages_Edit />}
                />
                <Route
                  path="/Location/Edit/:productId/:siteId"
                  element={<Location_Edit />}
                />
                <Route
                  path="/VaccationType/Edit/:productId/:siteId"
                  element={<VacctionType_Edit />}
                />

                <Route
                  path="/Product/Edit/:productId/:siteId"
                  element={<Product_Edit />}
                />
                <Route
                  path="/Product/Sales/:productId"
                  element={<Booking_Product />}
                />
                <Route
                  path="/Product/ImagesUpload/:productId/:productSku"
                  element={<Product_Images />}
                />
                <Route path="/User/Edit/:userId" element={<User_Edit />} />
                <Route
                  path="/Booking/Edit/:bookingId"
                  element={<Booking_Edit />}
                />
                <Route
                  path="/Booking/View/:bookingId"
                  element={<Booking_View />}
                />
                <Route
                  path="/Booking/Document/:bookingId/:bookingDBID"
                  element={<Booking_Docs />}
                />
                <Route
                  path="/Profile/View/:leadId"
                  element={<Profile_Page />}
                />
                <Route
                  path="/Payments/Edit/:paymentId"
                  element={<Payment_Edit />}
                />

                <Route path="/Autouser" element={<AutoUser />} />
                {localStorage.getItem("role") != "ADMIN" ? (
                  <Route path="/users" element={<Dashboard />} />
                ) : (
                  <Route path="/users" element={<User />} />
                )}

                {localStorage.getItem("role") != "ADMIN" ? (
                  <Route path="/payment" element={<Dashboard />} />
                ) : (
                  <Route path="/payment" element={<PaymentDetail />} />
                )}
              </>
            )}

            <Route path="/" element={<Login />} />
            {/* Check */}

            {/* <Route path="/users" element={<User />} /> */}
          </Routes>
        </AuthProvider>
      </Router>
    </main>
  );
}

export default App;
