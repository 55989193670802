import React, { useEffect, useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { getLeadStatusHistory } from "../../../API/Lead_API";
import { apiurl, authCode } from "../../../Host";

const LeadsModal = ({ modalShow, setModalShow, lead, onHide,statusHistory, ...props }) => {
  console.log(lead);
  useEffect(() => {
  }, []);

  return (
    <div>
      {" "}
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            {lead.name}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h4 className="statushead">Mail - {lead.email}, Phone - {lead.contact}</h4>
          <h4 className="statushead">Location - {lead.location}</h4>
          <table className="tabel table-bordered border-primary leadstatustable">
            <thead>
              <tr>
                <th>User Name</th>
                <th>Disposition</th>
                <th>Comment</th>
                <th>Date</th>
              </tr>
            </thead>
            <tbody>
              {statusHistory.map((item, i) => (
                <tr>
                  <td>{item.userName}</td>
                  <td>{item.disposition}</td>
                  <td>{item.comment}</td>
                  <td>{item.createDate}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={onHide}>Close</Button>
        </Modal.Footer>
      </Modal>
      l
    </div>
  );
};

export default LeadsModal;
