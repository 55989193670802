import React, { useEffect, useState } from "react";
import Load from "../../Image/product-loader.json";
import { Link, Navigate, useNavigate } from "react-router-dom";
import Lottie from "react-lottie";
import { Table } from "react-bootstrap";
import { useSelector } from "react-redux";
import { apiurl, authCode } from "../../Host";
import swal from "sweetalert";
import { async } from "rxjs/internal/scheduler/async";
import axios from "axios";
import moment from "moment";
import DataTable from "react-data-table-component";
const ViewBookingList = () => {
  const [loader, setLoader] = useState(false);
  const [bookings, setBookings] = useState([]);
  const [endDateForDownload, setEndDateForDownload] = useState("");
  const [startDateForDownload, setStartDateForDownload] = useState("");
  const [download, setDownload] = useState([]);
  const [excelButtonEnable, setExcelButtonEnable] = useState(true);
  const navigate = useNavigate();
  const auth = useSelector((state) => state.auth);
  const[bookingId,setbookingId]=useState("")
  var startDate = "";
  var endDate = "";
  const getAllBookings = () => {
    setLoader(true);
    var requestOptions = {
      method: "GET",
      redirect: "follow",
    };

    // fetch(
    //   apiurl +
    //     "/getAllBookingsCRM/" +
    //     auth.userData.roleId +
    //     "/" +
    //     auth.userData.userId +
    //     "?authCode=" +
    //     authCode,
    //   requestOptions
    // )
    fetch(apiurl + "allBookings", requestOptions)
      .then((response) => response.json())
      .then((result) => {
        setBookings(result);
        setLoader(false);
      })
      .catch((error) => console.log("error", error));
  };

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: Load,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };
  const getLeadsByDate = () => {
    let error = false;
    if (startDateForDownload === "" || endDateForDownload === "") {
      error = true;
      alert("Please Select Date");
    }
    if (error) {
      return;
    }
    setLoader(true);
    setExcelButtonEnable(false);
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      endDate: endDateForDownload,
      rollId: auth.userData.roleId,
      startDate: startDateForDownload,
      userId: auth.userData.userId,
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(apiurl + "/getBookingByDates?authCode=" + authCode, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        setBookings(result);
        setLoader(false);
      })
      .catch((error) => console.log("error", error));
  };

  const getByBookingId = () => {
    setLoader(true);
    var requestOptions = {
      method: "GET",
      redirect: "follow",
    };

    fetch(
      apiurl +
        "/getBookingByBookingId/" +
        auth.userData.roleId +
        "/" +
        auth.userData.userId +
        "/" +
        bookingId +
        "?authCode=" +
        authCode,
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        setBookings(result);
        setLoader(false);
      })
      .catch((error) => {
        setLoader(false);
        swal("Alert!", "No Data Found", "error");
      });
  };

  const setSearchBookingId = (e) => {
    setbookingId(e.target.value);
  };

  const setStartDate = (e) => {
    setStartDateForDownload(e.target.value);
    startDate = e.target.value;
  };

  const setEndDate = (e) => {
    setEndDateForDownload(e.target.value);
    endDate = e.target.value;
  };

  const downloadExcelData = async () => {
    const options = {
      method: "POST",
      responseType: "blob",
      url: `${apiurl}download`,
      headers: {
        "Content-Type": "application/json",
      },
      data: {
        startDate: startDateForDownload,
        endDate: endDateForDownload,
      },
    };

    const data = await axios.request(options).then((response) => {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "file.xlsx");
      document.body.appendChild(link);
      link.click();
    });
  };
  const columns = [
    {
      name: "Booking Id",
      selector: (row) => row.bookingId,
      sortable: true,
    },
    {
      name: "Name",
      selector: (row) => row.givenName,
      sortable: true,
    },
    {
      name: "Agent Id",
      selector: (row) => row.userId,
      sortable: true,
    },
    {
      name: "Agent Name",
      selector: (row) => row.userName,
      sortable: true,
    },
    {
      name: "Email",
      selector: (row) => row.email,
      sortable: true,
    },
    {
      name: "Booking Status",
      selector: (row) => row.bookingStatus,
      sortable: true,
    },
    {
      name: "Date",
      selector: (row) => moment(row.createDate).format("DD MMM, YYYY"),
      sortable: true,
    },
    {
      name: "Action",
      selector: (row) => (
        <div
          class="btn-group"
          role="group"
          aria-label="Basic mixed styles example"
        >
          <button
            type="button"
            class="btn btn-success"
            onClick={() => navigate("/Booking/Edit/" + row.id + "")}
          >
            <i class="fa-solid fa-pen"></i>
          </button>
          {/* <button
            type="button"
            class="btn btn-warning"
            onClick={() =>
              navigate("/Booking/Document/" + row.bookingId + "/" + row.id + "")
            }
          >
            <i class="fa-solid fa-upload"></i>
          </button> */}
        </div>
      ),
    },
  ];

  const conditionalRowStyles = [
    {
      when: (row) => row.dispositionId == 1,
      style: {
        backgroundColor: "#00800859",
        color: "white",
        "&:hover": {
          cursor: "pointer",
        },
      },
    },

    {
      when: (row) =>
        row.dispositionId == 2 ||
        row.dispositionId == 4 ||
        row.dispositionId == 5 ||
        row.dispositionId == 6,
      style: {
        backgroundColor: "#ff00005e",
        color: "white",
        "&:hover": {
          cursor: "pointer",
        },
      },
    },

    {
      when: (row) => row.dispositionId == 3 || row.dispositionId == 11,
      style: {
        backgroundColor: "#8080808a",
        color: "white",
        "&:hover": {
          cursor: "pointer",
        },
      },
    },

    {
      when: (row) => row.dispositionId == 7 || row.dispositionId == 8,
      style: {
        backgroundColor: "#fdbe008f",
        color: "white",
        "&:hover": {
          cursor: "pointer",
        },
      },
    },

    {
      when: (row) => row.dispositionId == 9,
      style: {
        backgroundColor: "rgba(255, 96, 0, 0.66)",
        color: "white",
        "&:hover": {
          cursor: "pointer",
        },
      },
    },

    {
      when: (row) => row.dispositionId == 10,
      style: {
        backgroundColor: "#0000ff4d",
        color: "white",
        "&:hover": {
          cursor: "pointer",
        },
      },
    },
  ];
  useEffect(() => {
    getAllBookings();
  }, []);
  return (
    <>
      <div className="form-box">
        <div className="container">
          <h1 className="form-head">
            <i class="fa-solid page-icon fa-ticket"></i>
            &nbsp;View Bookings
          </h1>
          <div className="row">
            <div className="col-3">
              <div className="search-leads">
                <div className="form-outline">
                  <input
                    type="search"
                    id="form1"
                    className="form-control"
                    placeholder="Search Booking Id"
                    value={bookingId}
                    onChange={(e) => setSearchBookingId(e)}
                  />
                </div>
                <button
                  type="button"
                  className="btn btn-danger"
                  onClick={() => getByBookingId()}
                >
                  <i class="fa-solid fa-magnifying-glass"></i>
                </button>
              </div>
            </div>

            <div className="col-3">
              <div className="search-leads">
                <div className="form-outline">
                  <input
                    type="date"
                    id="form1"
                    className="form-control"
                    placeholder="Start Date"
                    value={startDateForDownload}
                    onChange={(e) => setStartDate(e)}
                    max={moment().format("YYYY-MM-DD")}
                  />
                </div>
              </div>
            </div>

            <div className="col-3">
              <div className="search-leads">
                <div className="form-outline">
                  <input
                    type="date"
                    id="form1"
                    className="form-control"
                    placeholder="End Date"
                    value={endDateForDownload}
                    onChange={(e) => setEndDate(e)}
                    max={moment().format("YYYY-MM-DD")}
                  />
                </div>
              </div>
            </div>
            <div className="col-3">
              <button
                className="btn btn-danger me-2"
                onClick={() => getLeadsByDate()}
              >
                <i class="fa-solid fa-magnifying-glass"></i> Search
              </button>
              {auth.userData.roleId === 1 || auth.userData.roleId === 3 ? (
                <button
                  className="btn btn-danger "
                  variant="info"
                  disabled={excelButtonEnable}
                  onClick={() => downloadExcelData()}
                >
                  <i class="fa-solid fa-download"></i> Excel
                </button>
              ) : (
                ""
              )}
            </div>
          </div>
        </div>
      </div>
      {loader ? (
        <div className="loader-lotti">
          <Lottie options={defaultOptions} height={250} width={250} />
        </div>
      ) : (
        ""
      )}
      <div className={loader ? "parent-form form-box" : "form-box"}>
        <div className="container form-content"></div>
        {/* <h1 className="form-head">
          <i class="fa-solid page-icon fa-ticket"></i>
          &nbsp;Bookings
        </h1> */}
        <DataTable
          columns={columns}
          data={bookings}
          noHeader
          defaultSortField="id"
          paginationRowsPerPageOptions={[5, 10, 25, 50, 100]}
          defaultSortAsc={false}
          pagination
          highlightOnHover
          // selectableRows
          // selectableRowsVisibleOnly
          // onSelectedRowsChange={(e) => SelectItem(e)}
          conditionalRowStyles={conditionalRowStyles}
        />
        {/* <Table responsive="sm">
          <thead>
            <tr>
              <th>Booking Id</th>
              <th>Name</th>
              <th>Agent Id</th>
              <th>Agent Name</th>
              <th>Email</th>
              <th>Booking Status</th>
              <th>Date</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {bookings.map((item) => (
              <tr>
                <td>{item.bookingId}</td>
                <td>{item.givenName}</td>
                <td>{item.userId}</td>
                <td>{item.userName}</td>
                <td>{item.email}</td>
                <td>{item.bookingStatus}</td>
                <td>{moment(item.createDate).format("DD MMM, YYYY")}</td>

                <td>
                  <div
                    class="btn-group"
                    role="group"
                    aria-label="Basic mixed styles example"
                  >
                    <button
                      type="button"
                      class="btn btn-success"
                      onClick={() => navigate("/Booking/Edit/" + item.id + "")}
                    >
                      <i class="fa-solid fa-pen"></i>
                    </button>
                    <button
                      type="button"
                      class="btn btn-warning"
                      onClick={() =>
                        navigate(
                          "/Booking/Document/" +
                            item.bookingId +
                            "/" +
                            item.id +
                            ""
                        )
                      }
                    >
                      <i class="fa-solid fa-file"></i>
                    </button>
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </Table> */}
      </div>
    </>
  );
};

export default ViewBookingList;
