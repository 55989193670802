import React from "react";
import SideBar from "../../Atom/SideBar/SideBar";
import TopBar from "../../Atom/TopBar/TopBar";

const PaymentBar=()=>{
    return(
        <>
<SideBar/>
<TopBar/>
        </>
    )
}

export default PaymentBar;