import React, { useEffect, useState } from "react";
import { Table } from "react-bootstrap";
import { apiurl, authCode, siteId } from "../../Host";
import Load from "../../Image/product-loader.json";
import Lottie from "react-lottie";
import { async } from "rxjs/internal/scheduler/async";
import { useSelector } from "react-redux";
import { Link, Navigate, useNavigate } from "react-router-dom";
import swal from "sweetalert";

const ViewAllUsersList = () => {
  const auth = useSelector((state) => state.auth);
  const [loader, setLoader] = useState(false);
  const [users, setUsers] = useState([]);
  const navigate = useNavigate();

  const getAllUsers = async () => {
    var requestOptions = {
      method: "GET",
      redirect: "follow",
    };

    fetch(
      apiurl +
        "/getAllUsers/" +
        auth.userData.roleId +
        "/" +
        auth.userData.userId +
        "?authCode=" +
        authCode,
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        setUsers(result);
      })
      .catch((error) => console.log("error", error));
  };

  const makeUserDeactive = (id) => {
    var requestOptions = {
      method: "GET",
      redirect: "follow",
    };

    fetch(
      apiurl + "/makeUserDeactivate/" + id + "?authCode=" + authCode,
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        if (result.id != null) {
          swal("Good job!", "Just Deactivated " + result.userName, "success");
          getAllUsers();
        } else {
          swal("Alert!", "Something Went Erong", "error");
        }
      })
      .catch((error) => console.log("error", error));
  };

  const makeUserActive = (id) => {
    var requestOptions = {
      method: "GET",
      redirect: "follow",
    };

    fetch(
      apiurl + "/makeUserActive/" + id + "?authCode=" + authCode,
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        if (result.id != null) {
          swal("Good job!", "Just Activated" + result.userName, "success");
          getAllUsers();
        } else {
          swal("Alert!", "Something Went Erong", "error");
        }
      })
      .catch((error) => console.log("error", error));
  };

  useEffect(() => getAllUsers(), []);
  return (
    <div className="form-box">
      <div className="container form-content">
        <h1 className="form-head">
          <i class="fa-solid menu-icon page-icon fa-users"></i>&nbsp;All Users
        </h1>
      </div>
      <Table responsive="sm" className={loader ? "parent-form" : ""}>
        <thead>
          <tr>
            <th>#Databases Id</th>
            <th>User Name</th>
            <th>User Email</th>
            <th>Status</th>
            {/* <th>User Id</th> */}
            <th>Create Date</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
          {users.map((item) => (
            <tr>
              <td>{item.id}</td>
              <td>{item.userName}</td>
              <td>{item.userEmail}</td>
              <td>{item.status}</td>
              {/* <td>Table cell</td> */}
              <td>{item.createDate}</td>
              <td>
                <button
                  className="btn btn-success"
                  onClick={() => navigate("/User/Edit/" + item.id + "")}
                >
                  Edit
                </button>
              </td>
              <td>
                {item.status == 1 ? (
                  <button
                    className="btn btn-success"
                    onClick={() => makeUserDeactive(item.id)}
                  >
                    Active
                  </button>
                ) : (
                  <button
                    className="btn btn-danger"
                    onClick={() => makeUserActive(item.id)}
                  >
                    Deactive
                  </button>
                )}
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
    </div>
  );
};

export default ViewAllUsersList;
