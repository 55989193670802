import { LOGIN, LOGOUT } from "../ActionTypes/ActionType";

const initialState={
    isLoggedIn:false,
    userData:[]
}

export const AuthReducer  =(state= initialState , action) => {
    switch (action.type) { 
    case LOGIN:
        return  Object.assign({}, state, {
            isLoggedIn: true,
            userData: action.payload,
          });          
    case LOGOUT:
        return  Object.assign({}, state, {
            isLoggedIn: false,
            userData: null,
          });          
}
return state
}

