import React from "react";
import SideBar from "../Atom/SideBar/SideBar";
import TopBar from "../Atom/TopBar/TopBar";
import AddPackage from "../Molecule/Package/AddPackage";

const Packages = () => {
  return (
    <div>
      <TopBar />
      <SideBar />
      <AddPackage/>
    </div>
  );
};

export default Packages;
