import React, { useState } from "react";
import user from "../../Image/user.png";
import Dropdown from "react-bootstrap/Dropdown";
import { useNavigate } from "react-router";
import { Link } from "react-router-dom";
import authuser from "../../Image/authuser.png";
import { useSelector } from "react-redux";

const TopBar = () => {
  let navigate = useNavigate();
  const user = useSelector((state) => state.auth);
  function logout() {
    localStorage.clear();
    navigate("/");
  }

  let name = localStorage.getItem("name");
  let Phone = localStorage.getItem("phone");

  return (
    <div className="container-fluid">
      <div className="row">
        <div className="user">
          <Dropdown>
            <Dropdown.Toggle id="dropdown-basic">
              {/* <img src={user} className="user-button" id="dropdown-basic"/> */}
              <img src={authuser} className="user-logoutmenu-img" />
              <span className="user-logoutmenu-login"></span>
            </Dropdown.Toggle>

            <Dropdown.Menu>
              {/* <Dropdown.Item href="#/action-1">{name}</Dropdown.Item>
              <Dropdown.Item href="#/action-2">{Phone}</Dropdown.Item>
              <button className="btn-logout" onClick={() => logout()}>
                <Link to="/" className="logout-link">
                  <Dropdown.Item href="#/action-2"> Logout</Dropdown.Item>
                </Link>
              </button> */}
              <div className="d-flex align-items-center py-1">
                <div className="dropposition">
                  <img src={authuser} className="user-logoutmenu-img" />
                  <span className="user-logoutmenu-login"></span>
                </div>
                <div className="ps-3">
                  <strong className="d-block">{user.userData.userName}</strong>
                  <div className="text-sm  text-primary  font-weight-semibold ">
                    {user.userData.userEmail}
                  </div>
                </div>
              </div>

              <div className="dropdown-divider"></div>
              <button
                className="btn btn-light btn-sm w-100 font-weight-semibold sign-outbtn"
                onClick={() => logout()}
              >
                <i className="fa fa-sign-out me-2" aria-hidden="true"></i>
                Sign Out
              </button>
            </Dropdown.Menu>
          </Dropdown>
        </div>
      </div>
    </div>
  );
};
export default TopBar;
