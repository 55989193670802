import React, { useEffect, useState } from "react";
import Form_Button from "../../Custom-Components/Form_Button";
import Form_Select from "../../Custom-Components/Form_Select";
import { Table } from "react-bootstrap";
import axios from "axios";
import { apiurl } from "../../Host";
import { authCode } from "../../Host";
import Load from "../../Image/product-loader.json";
import Lottie from "react-lottie";
import DataTable from "react-data-table-component";
const WebsiteQueriesList = () => {
  const [queries, setQueries] = useState();
  const [siteId, setSiteId] = useState({ siteId: "" });
  const [loader, setLoader] = useState(false);

  const sitesId = [
    { id: 1, name: "Travomint-Vacations" },
    { id: 2, name: "ReservationsDeal-Vacations" },
  ];

  const GetValue = (e) => {
    setSiteId({ ...siteId, [e.target.name]: e.target.value });
  };

  const getWebsiteQueries = () => {
    setLoader(true);
    const options = {
      method: "GET",
      url: `${apiurl}/getQueriesBySiteId/${siteId.siteId}`,
      params: { authCode: authCode },
      headers: { "Content-Type": "application/json" },
    };

    axios
      .request(options)
      .then(function(response) {
        setQueries(response.data.reverse());
        setLoader(false);
      })
      .catch(function(error) {
        console.error(error);
      });
  };

  const handleSumitSiteid = (e) => {
    e.preventDefault();
    getWebsiteQueries();
  };
  const columns = [
    {
      name: "Name",
      selector: (row) => row.name,
      sortable: true,
      width: "11%",
    },
    {
      name: "Email",
      selector: (row) => row.email,
      sortable: true,
      width: "13%",
    },
    {
      name: "Phone",
      selector: (row) => row.phone,
      sortable: true,
      width: "11%",
    },
    {
      name: "Message",
      selector: (row) => row.message,
      sortable: true,
      width: "30%",
    },
    {
      name: "ProductName",
      selector: (row) => row.productName,
      sortable: true,
      width: "28%",
    },
    {
      name: "Image",
      selector: (row) => (
        <a href={row.imageText} target="_blank">
          {row.imageText == null ? (
            "Not available"
          ) : row.imageText.length > 20 ? (
            <i class="fa-solid fa-image "></i>
          ) : (
            row.imageText
          )}
        </a>
      ),
      // headerStyle: (selector,row) => {
      //    return { width: "20px", textAlign: "center" };
      // },
      width: "7%",
    },
  ];
   const conditionalRowStyles = [
     {
       when: (row) => row.dispositionId == 1,
       style: {
         backgroundColor: "#00800859",
         color: "white",
         "&:hover": {
           cursor: "pointer",
         },
       },
     },

     {
       when: (row) =>
         row.dispositionId == 2 ||
         row.dispositionId == 4 ||
         row.dispositionId == 5 ||
         row.dispositionId == 6,
       style: {
         backgroundColor: "#ff00005e",
         color: "white",
         "&:hover": {
           cursor: "pointer",
         },
       },
     },

     {
       when: (row) => row.dispositionId == 3 || row.dispositionId == 11,
       style: {
         backgroundColor: "#8080808a",
         color: "white",
         "&:hover": {
           cursor: "pointer",
         },
       },
     },

     {
       when: (row) => row.dispositionId == 7 || row.dispositionId == 8,
       style: {
         backgroundColor: "#fdbe008f",
         color: "white",
         "&:hover": {
           cursor: "pointer",
         },
       },
     },

     {
       when: (row) => row.dispositionId == 9,
       style: {
         backgroundColor: "rgba(255, 96, 0, 0.66)",
         color: "white",
         "&:hover": {
           cursor: "pointer",
         },
       },
     },

     {
       when: (row) => row.imageText,
       style: {
        innerWidth:"20px"
        
        
       },
     },
   ];
 const defaultOptions = {
   loop: true,
   autoplay: true,
   animationData: Load,
   rendererSettings: {
     preserveAspectRatio: "xMidYMid slice",
   },
 };
  // useEffect(() => {
  //   getWebsiteQueries();
  // }, [setQueries]);

  return (
    <div className="website_queries">
      <div>
        <h1 className="form-head">
          <i class="fa-brands page-icon page-icon fa-product-hunt"></i>
          &nbsp;Website Queries
        </h1>
        <form
          onSubmit={handleSumitSiteid}
          // className="d-flex justify-content-center align-items-center"
        >
          <div className="input-group d-flex justify-content-center align-items-center">
            <Form_Select
              label="Select Site ID"
              dropdown={sitesId}
              name="siteId"
              value={siteId.siteId}
              div_class="col-6 "
              onChange={(e) => GetValue(e)}
            />
            <Form_Button className="px-3 ms-4 mt-4 text-center" />
          </div>
        </form>
        {loader ? (
          <div className="loader-lotti mt-5">
            <Lottie options={defaultOptions} height={250} width={250} />
          </div>
        ) : (
          <DataTable
            columns={columns}
            data={queries}
            noHeader
            defaultSortField="id"
            paginationRowsPerPageOptions={[5, 10, 25, 50, 100]}
            defaultSortAsc={false}
            pagination
            highlightOnHover
            // selectableRows
            // selectableRowsVisibleOnly
            // onSelectedRowsChange={(e) => SelectItem(e)}
            conditionalRowStyles={conditionalRowStyles}
          />
        )}

        {/* <Table responsive="sm">
          <thead>
            <tr>
              <th>Name</th>
              <th>Email</th>
              <th>phone</th>
              <th>Message</th>
              <th>ProductName</th>
              <th>Image</th>
            </tr>
          </thead>
          <tbody>
            {queries &&
              queries.map((item) => {
                return (
                  <tr>
                    <td>{item.name}</td>
                    <td>{item.email}</td>
                    <td>{item.phone}</td>
                    <td>{item.message}</td>
                    <td>{item.productName}</td>
                    <td className="text-center">
                      <a href={item.imageText} target="_blank">
                        {item.imageText == null ? (
                          "Not available"
                        ) : item.imageText.length > 20 ? (
                          <i class="fa-solid fa-image"></i>
                        ) : (
                          item.imageText
                        )}
                      </a>
                    </td>
                  </tr>
                );
              })}
          </tbody>
        </Table> */}
      </div>
    </div>
  );
};

export default WebsiteQueriesList;
