import React from "react";
import SideBar from "../Atom/SideBar/SideBar";
import TopBar from "../Atom/TopBar/TopBar";
import { useParams } from "react-router";
import EditUserForm from "../Atom/Form/EditUserForm";
const User_Edit = () => {
  const userId = useParams();
  return (
    <div>
      <TopBar />
      <SideBar />
      <EditUserForm userId={userId}/>
    </div>
  );
};

export default User_Edit;
