import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import "bootstrap/dist/css/bootstrap.min.css";
import {} from "./Style.css";
import { Provider } from "react-redux";
import store, { persistor } from "./Redux/Store/Store";
import { AuthProvider } from "./API/AuthContextApi";
import 'bootstrap/dist/css/bootstrap.min.css';
import { PersistGate } from 'redux-persist/integration/react'

ReactDOM.render(
  <>
    <Provider store={store}>
    <PersistGate persistor={persistor} loading={null}>
      <App />
      </PersistGate>
    </Provider>
  </>,

  document.getElementById("root")
);
