import React, { useState } from "react";
import Offcanvas from "react-bootstrap/Offcanvas";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import Accordion from "react-bootstrap/Accordion";
import icon from "../../Image/list.png";
import logo from "../../Image/logo.svg";
import { useDispatch, useSelector } from "react-redux";
import { LOGOUT } from "../../Redux/ActionTypes/ActionType";
const SideBar = () => {
  const dispatch = useDispatch();
  const auth = useSelector((state) => state.auth);
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  let navigate = useNavigate();

  function logout() {
    dispatch({
      type: LOGOUT,
      payload: null,
    });
    navigate("/");
  }

  return (
    <div>
      <div className="sideNav" onClick={handleShow}>
        <i class="fa-solid sideNav-menu fa-align-justify fa-2x"></i>
      </div>

      <Offcanvas show={show} onHide={handleClose}>
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>
            <Link to="/dashboard" className="link">
              <img src={logo} width="70%" />
            </Link>
          </Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <div className="">
            {/* User is Only for Admin Roll - 1 & 2*/}
            {auth.userData.roleId === 1 || auth.userData.roleId === 2 ? (
              <Accordion>
                <Accordion.Item eventKey="0">
                  <Accordion.Header className="dashboard-item">
                    <div>
                      <i class="fa-solid menu-icon fa-users"></i>
                      &nbsp; Users
                    </div>
                  </Accordion.Header>
                  <Accordion.Body>
                    <Link to="/users" className="link">
                      {" "}
                      <div className="dashboard-item">
                        {" "}
                        <i class="fa-solid menu-icon fa-at"></i>&nbsp; Add Users
                      </div>
                    </Link>
                    <Link to="/viewUsers" className="link">
                      {" "}
                      <div className="dashboard-item">
                        {" "}
                        <i class="fa-solid menu-icon fa-eye"></i>
                        &nbsp; View Users
                      </div>
                    </Link>
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>
            ) : (
              ""
            )}

            {auth.userData.roleId === 4 ||
            auth.userData.roleId === 1 ||
            auth.userData.userId === "63205793b9de334fcc9e0c33" ? (
              <>
                <Link to="/country" className="link">
                  {" "}
                  <div className="dashboard-item">
                    {" "}
                    <i class="fa-solid menu-icon fa-flag-usa"></i>&nbsp; Country
                  </div>
                </Link>
                <Link to="/package" className="link">
                  {" "}
                  <div className="dashboard-item">
                    {" "}
                    <i class="fa-solid menu-icon fa-suitcase"></i>&nbsp;
                    Packages
                  </div>
                </Link>
                <Link to="/vaccationtype" className="link">
                  {" "}
                  <div className="dashboard-item">
                    {" "}
                    <i class="fa-solid menu-icon fa-tree-city"></i>&nbsp;
                    Vaccation Type
                  </div>
                </Link>
                <Link to="/locations" className="link">
                  {" "}
                  <div className="dashboard-item">
                    {" "}
                    <i class="fa-solid menu-icon fa-map-location-dot"></i>&nbsp;
                    Locations
                  </div>
                </Link>
                <Accordion>
                  <Accordion.Item eventKey="1">
                    <Accordion.Header className="dashboard-item">
                      <div>
                        <i class="fa-brands menu-icon fa-product-hunt"></i>
                        &nbsp; Products
                      </div>
                    </Accordion.Header>
                    <Accordion.Body>
                      <Link to="/products" className="link">
                        {" "}
                        <div className="dashboard-item">
                          {" "}
                          <i class="fa-solid menu-icon fa-at"></i>&nbsp; Add
                          Products
                        </div>
                      </Link>
                      <Link to="/viewProducts" className="link">
                        {" "}
                        <div className="dashboard-item">
                          {" "}
                          <i class="fa-solid menu-icon fa-eye"></i>
                          &nbsp; View Products
                        </div>
                      </Link>
                    </Accordion.Body>
                  </Accordion.Item>
                </Accordion>
              </>
            ) : (
              ""
            )}

            {auth.userData.roleId === 2 ||
            auth.userData.roleId === 1 ||
            auth.userData.roleId === 3 ? (
              <>
                <div className="sidebar-nav">
                  <Accordion>
                    <Accordion.Item eventKey="0">
                      <Accordion.Header className="dashboard-item">
                        <div>
                          <i class="fa-solid menu-icon fa-ticket"></i>&nbsp;
                          Bookings
                        </div>
                      </Accordion.Header>
                      <Accordion.Body>
                        <Link to="/bookings" className="link">
                          {" "}
                          <div className="dashboard-item">
                            {" "}
                            <i class="fa-solid menu-icon fa-at"></i>&nbsp;
                            Create Booking
                          </div>
                        </Link>
                        <Link to="/bookingList" className="link">
                          {" "}
                          <div className="dashboard-item">
                            {" "}
                            <i class="fa-solid menu-icon fa-eye"></i>
                            &nbsp; View Bookings
                          </div>
                        </Link>
                      </Accordion.Body>
                    </Accordion.Item>
                  </Accordion>
                </div>

                <div className="sidebar-nav">
                  <Accordion>
                    <Accordion.Item eventKey="0">
                      <Accordion.Header className="dashboard-item">
                        <div>
                          <i class="fa-solid menu-icon fa-money-bill"></i>&nbsp;
                          Payments
                        </div>
                      </Accordion.Header>
                      <Accordion.Body>
                        <Link to="/addPayments" className="link">
                          {" "}
                          <div className="dashboard-item">
                            {" "}
                            <i class="fa-solid menu-icon fa-at"></i>&nbsp;
                            Create Payments
                          </div>
                        </Link>
                        <Link to="/viewPayments" className="link">
                          {" "}
                          <div className="dashboard-item">
                            {" "}
                            <i class="fa-solid menu-icon fa-eye"></i>
                            &nbsp; View Payments
                          </div>
                        </Link>
                      </Accordion.Body>
                    </Accordion.Item>
                  </Accordion>
                </div>

                <div className="sidebar-nav">
                  <Accordion>
                    <Accordion.Item eventKey="0">
                      <Accordion.Header className="dashboard-item">
                        <div>
                          <i class="fa-solid menu-icon fa-headset"></i>&nbsp;
                          Leads
                        </div>
                      </Accordion.Header>
                      <Accordion.Body>
                        <Link to="/addLeads" className="link">
                          {" "}
                          <div className="dashboard-item">
                            {" "}
                            <i class="fa-solid menu-icon fa-at"></i>&nbsp; Add
                            Leads
                          </div>
                        </Link>
                        <Link to="/viewLeads" className="link">
                          {" "}
                          <div className="dashboard-item">
                            {" "}
                            <i class="fa-solid menu-icon fa-flag-checkered"></i>
                            &nbsp; Your Leads
                          </div>
                        </Link>
                        <Link to="/websiteQueries" className="link">
                          {" "}
                          <div className="dashboard-item">
                            {" "}
                            <i class="fa-solid menu-icon fa-eye"></i>
                            &nbsp; Website Queries
                          </div>
                        </Link>
                      </Accordion.Body>
                    </Accordion.Item>
                  </Accordion>
                </div>
              </>
            ) : (
              ""
            )}

            {auth.userData.roleId === 5 || auth.userData.roleId === 1 ? (
              <>
                {/* <Link to="/locations" className="link">
                  {" "}
                  <div className="dashboard-item">
                    {" "}
                    <i class="fa-brands menu-icon fa-cc-visa"></i>&nbsp; Visa
                    Queries
                  </div>
                </Link> */}
                {/* <Link to="/locations" className="link">
                  {" "}
                  <div className="dashboard-item">
                    {" "}
                    <i class="fa-solid menu-icon fa-helicopter"></i>&nbsp;
                    Charter Queries
                  </div>
                </Link> */}
              </>
            ) : (
              ""
            )}

            {/* <Link to="/Autouser" className="link"> <div className="dashboard-item"> <i class="fa-solid menu-icon fa-users-rectangle"></i>&nbsp; Auto-Booking</div></Link> */}
            {/* <Link to="/supplier" className="link"> <div className="dashboard-item"> <i class="fa-solid menu-icon fa-users-rectangle"></i>&nbsp; Supplier Handler</div></Link> */}
            {/* <Link to="/list" className="link"> <div className="dashboard-item"> <i class="fa-solid menu-icon fa-users-rectangle"></i>&nbsp; Booking List</div></Link> */}
            {localStorage.getItem("role") === "ADMIN" ? (
              <Link to="/payment" className="link">
                {" "}
                <div className="dashboard-item">
                  {" "}
                  <i class="fa-solid menu-icon fa-users-rectangle"></i>&nbsp;
                  Payment Detail
                </div>
              </Link>
            ) : (
              ""
            )}
            {localStorage.getItem("role") === "ADMIN" ? (
              <Link to="/users" className="link">
                {" "}
                <div className="dashboard-item">
                  {" "}
                  <i class="fa-solid menu-icon fa-users-rectangle"></i>&nbsp;
                  Users
                </div>
              </Link>
            ) : (
              ""
            )}
          </div>

          {/* <div className="">
            <button to="" className="link" onClick={() => logout()}>
              {" "}
              <div className="logout">
                {" "}
                <i class="fa-solid menu-icon fa-right-from-bracket"></i> &nbsp;
                Logout
              </div>
            </button>
          </div> */}
        </Offcanvas.Body>
      </Offcanvas>
    </div>
  );
};
export default SideBar;
