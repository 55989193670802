import React from "react";
import Form_Button from "../../Custom-Components/Form_Button";
import Form_Input from "../../Custom-Components/Form_Input";
import Form_Select from "../../Custom-Components/Form_Select";
import { apiurl, authCode } from "../../Host";
import { useEffect, useState } from "react";
import { Leads_API } from "../../API/Lead_API";
import { useSelector } from "react-redux";
import swal from "sweetalert";

const AddLeadsForm = () => {
  const auth = useSelector((state) => state.auth);

  const [leads, setLeadsValue] = useState({
    contact: "",
    destination: "",
    email: "",
    name: "",
    siteId: "",
    managerId: auth.userData.managerId,
    rollId: auth.userData.roleId,
    userId: auth.userData.userId,
    userName: auth.userData.userName,
  });

  const SubmitLeads = (e) => {
    e.preventDefault();
    Leads_API(leads);
  };

  const GetValue = (e) => {
    setLeadsValue({ ...leads, [e.target.name]: e.target.value });
  };

  const sites = [
    { id: 1, name: "Travomint-Vaccations", value: 1 },
    { id: 2, name: "ReservationsDeal-Vaccations", value: 2 },
  ];

  console.log("leads.siteId", leads.siteId);

  const GetCsvJson = (e) => {
    e.preventDefault();
    console.log(e.target.files);
    swal({
      title: "Are you sure?",
      text: "Once Uploaded, New leads will be saved and existing will be Updated",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        var formdata = new FormData();
        formdata.append("userName", auth.userData.userName);
        formdata.append("file", e.target.files);
        formdata.append("authCode", authCode);
        formdata.append("userId", auth.userData.userId);
        var requestOptions = {
          method: "POST",
          body: formdata,
          redirect: "follow",
        };

        fetch(apiurl + "/uploadLeads", requestOptions)
          .then((response) => response.json())
          .then((result) => {
            if (result.statusCode == 200) {
              swal(result.message, {
                icon: "success",
              });
            }
          })
          .catch((error) => console.log("error", error));
      } else {
        swal("Your request is cancelled", {
          icon: "error",
        });
      }
    });
  };

  console.log("Adding Leads", leads);

  useEffect(() => {}, []);
  return (
    <div>
      <div className="form-box">
        <div className="container form-content">
          <h1 className="form-head">
            <i class="fa-solid page-icon fa-headset"></i>&nbsp;Add Leads
          </h1>
          <form onSubmit={SubmitLeads}>
            <div className="row">
              <Form_Select
                label="Select Site"
                name="siteId"
                dropdown={sites}
                id="sites"
                value={leads.siteId}
                onChange={(e) => GetValue(e)}
              />
              <Form_Input
                label="Name"
                name="name"
                div_class="col-6"
                id="name"
                value={leads.name}
                onChange={(e) => GetValue(e)}
              />
              <Form_Input
                label="Contact"
                name="contact"
                div_class="col-6"
                id="contact"
                type="tel"
                value={leads.contact}
                onChange={(e) => GetValue(e)}
              />
              <Form_Input
                label="Email"
                name="email"
                div_class="col-6"
                id="email"
                type="email"
                value={leads.email}
                onChange={(e) => GetValue(e)}
              />
              <Form_Input
                label="Destination"
                name="destination"
                div_class="col-6"
                id="destination"
                type="text"
                value={leads.destination}
                onChange={(e) => GetValue(e)}
              />

              <Form_Button />
            </div>
          </form>
        </div>
      </div>

      <div className="form-box">
        <div className="container">
          <div className="row">
            <Form_Input
              label="Upload File"
              name="leadsUpload"
              div_class="col-6"
              id="leadsUpload"
              type="file"
              // accept=".csv"
              onChange={(e) => GetCsvJson(e)}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddLeadsForm;
