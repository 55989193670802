import React from 'react'
import AddProductForm from '../../Atom/Form/AddProductForm'


const AddProduct = () => {
  return (
    <AddProductForm/>
  )
}

export default AddProduct
