import React from 'react'
import TopBar from '../Atom/TopBar/TopBar'
import SideBar from "../Atom/SideBar/SideBar";
import AddLeadsForm from '../Atom/Form/AddLeadsForm'
const Leads = () => {
    return(
        <div>
            <TopBar/>
            <SideBar/>
            <AddLeadsForm/>
        </div>
    )
}

export default Leads;