import React from 'react'
import SideBar from '../../Atom/SideBar/SideBar'
import TopBar from '../../Atom/TopBar/TopBar'
import ViewAllUsersList from './ViewAllUsersList'

const ViewAllUsers = () => {
  return (
    <>
    <TopBar/>
    <SideBar/>
    <ViewAllUsersList/>
    </>
  )
}

export default ViewAllUsers