import React, { useEffect, useState } from "react";
import { Table } from "react-bootstrap";
import { Link, Navigate, useNavigate } from "react-router-dom";
import { apiurl, authCode } from "../../Host";
import { useSelector } from "react-redux";
import Simple_Input from "../../Custom-Components/Simple_Input";
import Simple_Select from "../../Custom-Components/Simple_Select";
import Form_Select from "../../Custom-Components/Form_Select";
import Form_Button from "../../Custom-Components/Form_Button";
import swal from "sweetalert";
import { Alert } from "bootstrap";
import LeadsModal from "./Modals/LeadsModal";
import { getLeadStatusHistory, updateLeadNote } from "../../API/Lead_API";
import Load from "../../Image/loader.json";
import Lottie from "react-lottie";

import Form_CheckBox from "../../Custom-Components/Form_CheckBox";
import Form_Radio from "../../Custom-Components/Form_Radio";
import DataTable from "react-data-table-component";
import { color } from "@mui/system";
import LeadsCommentsModal from "./Modals/LeadsCommentsModal";
import e from "cors";

const ViewLeadsForm = () => {
  const [leads, setLeads] = useState([]);
  const [managers, setManagers] = useState([]);
  const [agents, setAgents] = useState([]);
  const auth = useSelector((state) => state.auth);
  var managerId = "";
  var managerName = "";
  var agentId = "";
  var agentName = "";
  var leadDisposition = "";
  var leadDispositionId = "";
  var leadComment = "";
  var searchEmail = "";
  var startDate = "";
  var endDate = "";
  var searchPhone = "";
  // Modal State
  const [modalShow, setModalShow] = useState(false);

  const [leadCommentModal, setLeadCommentModal] = useState(false);
  const [lastComment, setLastComment] = useState({});
  const [leadHistoryId, setLeadHistoryId] = useState({});
  const [check, setCheck] = useState([]);
  const [statusHistory, setStatusHistory] = useState([]);
  const [loaderStatus, setLoaderStatus] = useState(false);
  const navigate = useNavigate();

  // For Notes
  const [leadForNotes, setLeadForNotes] = useState({});
  const [notesHistory, setNotesHistroy] = useState({});

  const columns = [
    {
      name: "name",
      selector: "name",
      sortable: true,
      cell: (row) => (
        <span
          className="lead-email"
          onClick={() => navigate("/Profile/View/" + row.id + "")}
        >
          <b>{row.name}</b>
        </span>
      ),
    },
    {
      name: "Contact",
      selector: "contact",
      sortable: true,
    },
    {
      name: "Email",
      selector: "email",
      sortable: true,
      cell: (row) => (
        <a className="lead-email" onClick={() => openModal(row)}>
          {row.email}
        </a>
      ),
    },
    {
      name: "Date",
      selector: "createDate",
      sortable: true,
      cell: (row) => <a>{formatDate(row.createDate)}</a>,
    },
    {
      name: "Manager",
      selector: "managerName",
      sortable: true,
      style: {
        color: "green",
        "font-weight": "bold",
      },
    },
    {
      name: "Agent",
      selector: "agentName",
      sortable: true,
      style: {
        color: "red",
        "font-weight": "bold",
      },
    },
    {
      name: "Form Name",
      selector: "formName",
      sortable: true,
    },
    {
      name: "Site Id",
      selector: "siteId",
      sortable: true,
    },
    {
      name: "Comment",
      sortable: true,
      cell: (row) => <Simple_Input onChange={(e) => getLeadComment(e)} />,
    },
    {
      name: "Disposition",
      sortable: true,
      cell: (row) => (
        <Simple_Select
          dropdown={dispositions}
          id="status"
          name="status"
          onChange={(e) => getDisposition(e)}
        />
      ),
    },
    {
      name: "Action",
      sortable: true,
      cell: (row) => (
        <div
          class="btn-group"
          role="group"
          aria-label="Basic mixed styles example"
        >
          <button
            type="button"
            class="btn btn-success"
            onClick={(e) => updateLeadDisposition(row)}
          >
            <i class="fa-solid fa-check"></i>
          </button>
          <button
            type="button"
            class="btn btn-warning"
            onClick={() => showNotesModal(row)}
          >
            <i class="fa-solid fa-pen"></i>
          </button>
        </div>
      ),
    },
  ];

  const conditionalRowStyles = [
    {
      when: (row) => row.dispositionId == 1,
      style: {
        backgroundColor: "#00800859",
        color: "white",
        "&:hover": {
          cursor: "pointer",
        },
      },
    },

    {
      when: (row) =>
        row.dispositionId == 2 ||
        row.dispositionId == 4 ||
        row.dispositionId == 5 ||
        row.dispositionId == 6,
      style: {
        backgroundColor: "#ff00005e",
        color: "white",
        "&:hover": {
          cursor: "pointer",
        },
      },
    },

    {
      when: (row) => row.dispositionId == 3 || row.dispositionId == 11,
      style: {
        backgroundColor: "#8080808a",
        color: "white",
        "&:hover": {
          cursor: "pointer",
        },
      },
    },

    {
      when: (row) => row.dispositionId == 7 || row.dispositionId == 8,
      style: {
        backgroundColor: "#fdbe008f",
        color: "white",
        "&:hover": {
          cursor: "pointer",
        },
      },
    },

    {
      when: (row) => row.dispositionId == 9,
      style: {
        backgroundColor: "rgba(255, 96, 0, 0.66)",
        color: "white",
        "&:hover": {
          cursor: "pointer",
        },
      },
    },

    {
      when: (row) => row.dispositionId == 10,
      style: {
        backgroundColor: "#0000ff4d",
        color: "white",
        "&:hover": {
          cursor: "pointer",
        },
      },
    },
  ];

  // console.log('leads-',leads)

  const leadHistoryIdLength = Object.keys(leadHistoryId).length;

  const dispositions = [
    { id: 1, name: "Sold", value: "Sold" },
    { id: 2, name: "Call Back", value: "Call Back" },
    { id: 3, name: "Not Intrested", value: "Not Intrested" },
    { id: 4, name: "No Answer", value: "No Answer" },
    { id: 5, name: "Answering Machine", value: "Answering Machine" },
    { id: 6, name: "DND", value: "DND" },
    { id: 7, name: "Initial Mail", value: "Initial Mail" },
    { id: 8, name: "Contacted", value: "Contacted" },
    { id: 9, name: "Follow Up", value: "Follow Up" },
    { id: 10, name: "Prospect", value: "Prospect" },
    { id: 11, name: "Invalid", value: "Invalid" },
  ];

  const formatDate = (string) => {
    var options = { year: "numeric", month: "long", day: "numeric" };
    return new Date(string).toLocaleDateString([], options);
  };
  console.log("auth::", auth);
  const getLeads = async () => {
    var requestOptions = {
      method: "GET",
      redirect: "follow",
    };
    console.log(
      apiurl +
        "/getLeads/" +
        auth.userData.roleId +
        "/" +
        auth.userData.userId +
        "?authCode=" +
        authCode
    );
    fetch(
      apiurl +
        "/getLeads/" +
        auth.userData.roleId +
        "/" +
        auth.userData.userId +
        "?authCode=" +
        authCode,
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        console.log("Just Called API");
        setLeads(result);
      })
      .catch((error) => console.log("error", error));
  };

  const getActiveManagers = () => {
    var requestOptions = {
      method: "GET",
      redirect: "follow",
    };

    fetch(apiurl + "/getAllManagers/2?authCode=" + authCode, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        setManagers(result);
      })
      .catch((error) => console.log("error", error));
  };

  const getActiveAgents = () => {
    var requestOptions = {
      method: "GET",
      redirect: "follow",
    };

    fetch(apiurl + "/getAllManagers/3?authCode=" + authCode, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        setAgents(result);
      })
      .catch((error) => console.log("error", error));
  };

  const SelectItem = (e) => {
    if (e) {
      setCheck(e.selectedRows);
      // check.push(item);
    } else {
      // check.pop(item);
    }
  };

  const getManager = (e) => {
    const idx = e.target.selectedIndex;
    const option = e.target.querySelectorAll("option")[idx];
    const name = option.getAttribute("name");
    managerName = name;
    managerId = e.target.value;
  };

  const getAgent = (e) => {
    const idx = e.target.selectedIndex;
    const option = e.target.querySelectorAll("option")[idx];
    const name = option.getAttribute("name");
    agentName = name;
    agentId = e.target.value;
  };

  const getDispositionId = (e) => {
    const idx = e.target.selectedIndex;
    const option = e.target.querySelectorAll("option")[idx];
    const id = option.getAttribute("id");
    leadDispositionId = id;
  };

  const getLeadComment = (e) => {
    leadComment = e.target.value;
  };

  const getDisposition = (e) => {
    getDispositionId(e);
    leadDisposition = e.target.value;
  };

  const updateLeadDisposition = (row) => {
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      comment: leadComment,
      disposition: leadDisposition,
      leadId: row.id,
      userId: auth.userData.userId,
      userName: auth.userData.userName,
      dispositionId: leadDispositionId,
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };
    fetch(apiurl + "/updateLeadStatus?authCode=" + authCode, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result.statusCode == 200) {
          swal("Good job!", result.message, "success");
        } else {
          swal("Alert!", result.message, "error");
        }
      })
      .catch((error) => console.log("error", error));
  };

  const updateMangers = () => {
    check.map((item, i) => {
      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");

      var raw = JSON.stringify({
        leadId: item.id,
        managerId: managerId,
        managerName: managerName,
      });

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      fetch(
        apiurl + "/distributeLeadManager?authCode=" + authCode,
        requestOptions
      )
        .then((response) => response.json())
        .then((result) => {
          if (result.id != null) {
            swal("Good job!", "Distributed to Manager", "success");
          }
        })
        .catch((error) => console.log("error", error));
    });
  };

  const updateAgents = () => {
    check.map((item, i) => {
      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");

      var raw = JSON.stringify({
        leadId: item.id,
        agentId: agentId,
        agentName: agentName,
      });

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      fetch(
        apiurl + "/distributeLeadAgent?authCode=" + authCode,
        requestOptions
      )
        .then((response) => response.json())
        .then((result) => {
          if (result.id != null) {
            swal("Good job!", "Distributed to Agent", "success");
          }
        })
        .catch((error) => console.log("error", error));
    });
  };

  const setSearchPhone = (e) => {
    searchPhone = e.target.value;
  };
  const setEmail = (e) => {
    searchEmail = e.target.value;
  };

  const setStartDate = (e) => {
    startDate = e.target.value;
  };

  const setEndDate = (e) => {
    endDate = e.target.value;
  };

  const searchByEmail = () => {
    setLoaderStatus(true);
    var requestOptions = {
      method: "GET",
      redirect: "follow",
    };

    fetch(
      apiurl +
        "/getSearchByEmail/" +
        searchEmail +
        "/" +
        auth.userData.roleId +
        "/" +
        auth.userData.userId +
        "?authCode=" +
        authCode,
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        setLeads(result);
        setLoaderStatus(false);
      })
      .catch((error) => console.log("error", error));
  };

  const searchByPhone = () => {
    var requestOptions = {
      method: "GET",
      redirect: "follow",
    };
    console.log(
      apiurl +
        "/getByPhoneNumber/" +
        auth.userData.roleId +
        "/" +
        auth.userData.userId +
        "/" +
        searchPhone +
        "?authCode=" +
        authCode
    );
    fetch(
      apiurl +
        "/getByPhoneNumber/" +
        auth.userData.roleId +
        "/" +
        auth.userData.userId +
        "/" +
        searchPhone +
        "?authCode=" +
        authCode,
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        setLeads(result);
        setLoaderStatus(false);
      })
      .catch((error) => console.log("error", error));
  };

  const getLeadsByDateFilter = () => {
    setLoaderStatus(true);
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      endDate: endDate,
      rollId: auth.userData.roleId,
      startDate: startDate,
      userId: auth.userData.userId,
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(apiurl + "/getLeadsByDate?authCode=" + authCode, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        setLeads(result);
        setLoaderStatus(false);
      })
      .catch((error) => console.log("error", error));
  };

  const showLeadHistroy = (lead) => {
    setLeadHistoryId(lead);
    setModalShow(true);
  };

  // console.log("Managers",managers);
  const openModal = (item) => {
    showLeadHistroy(item);
    getLeadStatusHistory({ lead: item.id, setStatusHistory });
  };

  const showNotesModal = (item) => {
    setLeadForNotes(item);
    setLeadCommentModal(true);
    // Call API and Put in State
    var requestOptions = {
      method: "GET",
      redirect: "follow",
    };

    fetch(
      apiurl + "/getLeadNotesHistory/" + item.id + "?authCode=" + authCode,
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        if (result !== null) {
          setNotesHistroy(result);
        }
      })
      .catch((error) => console.log("error", error));
  };

  const updateLeadNotes = () => {
    setLeadCommentModal(false);
    updateLeadNote(notesHistory, auth.userData, leadForNotes);
  };

  const getLeadsByAgent = (e) => {
    setLoaderStatus(true);
    if (e.target.checked) {
      var requestOptions = {
        method: "GET",
        redirect: "follow",
      };

http: fetch(
  apiurl +
    "/getLeadsByAgentid/" +
    auth.userData.roleId +
    "/" +
    e.target.value +
    "?authCode=" +
    authCode,
  requestOptions
)
  .then((response) => response.json())
  .then((result) => {
    setLeads(result);
    setLoaderStatus(false);
  })
  .catch((error) => console.log("error", error));
    }
  };

  const getLeadsByDisposition = (e) => {
    setLoaderStatus(true);
    if (e.target.checked) {
      var requestOptions = {
        method: "GET",
        redirect: "follow",
      };

      fetch(
        apiurl +
          "/getByDispositionId/" +
          auth.userData.roleId +
          "/" +
          auth.userData.userId +
          "/" +
          e.target.value +
          "?authCode=" +
          authCode,
        requestOptions
      )
        .then((response) => response.json())
        .then((result) => {
          setLeads(result);
          setLoaderStatus(false);
        })
        .catch((error) => console.log("error", error));
    }
  };

  const hideNotesModal = () => {
    setLeadCommentModal(false);
  };

  const clearFilters = () => {
    getLeads();
  };

  const autoDistributeAgents = () => {
    swal({
      title: "Are you sure?",
      text:
        "All not assgined leads will be distributed randomly amoung active agents",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        var requestOptions = {
          method: "GET",
          redirect: "follow",
        };

        fetch(apiurl + "/autoDistribute?authCode=" + authCode, requestOptions)
          .then((response) => response.json())
          .then((result) => {
            if (result.statusCode) {
              swal(result.message, {
                icon: "success",
              });
            } else {
              swal("Something went Wrong Contact Development Team");
            }
          })
          .catch((error) => console.log("error", error));
      } else {
        swal("You can distribute manualy");
      }
    });
  };

  useEffect(() => {
    getLeads();
    getActiveManagers();
    getActiveAgents();
  }, []);

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: Load,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  const handleUpdateNotes = (e, name) => {
    setNotesHistroy({ ...notesHistory, [name]: e.target.value });
  };

  return (
    <>
      <div className="form-container">
        <div className="form-box spaces">
          <div className="container">
            <h1 className="form-head">Vaccations Leads</h1>
            <div className="row">
              <div className="col-3">
                <div className="search-leads">
                  <div className="form-outline">
                    <input
                      type="search"
                      id="form1"
                      className="form-control"
                      placeholder="Search Phone"
                      onChange={(e) => setSearchPhone(e)}
                    />
                  </div>
                  <button
                    type="button"
                    className="btn btn-danger"
                    onClick={() => searchByPhone()}
                  >
                    <i class="fa-solid fa-magnifying-glass"></i>
                  </button>
                </div>
              </div>
              <div className="col-3">
                <div className="search-leads">
                  <div className="form-outline">
                    <input
                      type="search"
                      id="form1"
                      className="form-control"
                      placeholder="Search Email"
                      onChange={(e) => setEmail(e)}
                    />
                  </div>
                  <button
                    type="button"
                    className="btn btn-danger"
                    onClick={() => searchByEmail()}
                  >
                    <i class="fa-solid fa-magnifying-glass"></i>
                  </button>
                </div>
              </div>

              <div className="col-2">
                <div className="search-leads">
                  <div className="form-outline">
                    <input
                      type="date"
                      id="form1"
                      className="form-control"
                      placeholder="Start Date"
                      onChange={(e) => setStartDate(e)}
                    />
                  </div>
                </div>
              </div>

              <div className="col-2">
                <div className="search-leads">
                  <div className="form-outline">
                    <input
                      type="date"
                      id="form1"
                      className="form-control"
                      placeholder="End Date"
                      onChange={(e) => setEndDate(e)}
                    />
                  </div>
                </div>
              </div>
              <div className="col-2">
                <button
                  className="btn btn-danger"
                  onClick={() => getLeadsByDateFilter()}
                >
                  <i class="fa-solid fa-magnifying-glass"></i> Search
                </button>
              </div>
            </div>
          </div>
        </div>
        {loaderStatus ? (
          <div className="loader-lotti">
            <Lottie options={defaultOptions} height={200} width={200} />
          </div>
        ) : (
          ""
        )}

        <div className={loaderStatus ? "parent-form" : ""}>
          {auth.userData.roleId <= 3 ? (
            <div className="form-box spaces">
              <div className="container">
                <div className="row"></div>
                <div className="row">
                  {auth.userData.roleId <= 3 ? (
                    <>
                      <div className="col-6 form-group parent">
                        {auth.userData.roleId <= 2 ? (
                          <>
                            <div className="form-group">
                              <label className="col-3">Active Agents</label>
                            </div>
                            <div className="form-group lft">
                              <select
                                className="col-3"
                                onChange={(e) => getAgent(e)}
                                required
                              >
                                <option value="">-Please Select-</option>
                                {agents.map((item) => {
                                  return (
                                    <option
                                      value={item.id}
                                      name={item.userName}
                                    >
                                      {item.userName}
                                    </option>
                                  );
                                })}
                              </select>
                            </div>
                            <div className="form-group rht">
                              <Form_Button onClick={(e) => updateAgents()} />
                            </div>
                          </>
                        ) : (
                          ""
                        )}
                        <div>
                          {auth.userData.roleId === 1 ? (
                            <>
                              <div className=" form-group">
                                <label className="col-3">Active Managers</label>
                              </div>
                              <div className="form-group lft">
                                <select
                                  className="col-3"
                                  onChange={(e) => getManager(e)}
                                  required
                                >
                                  <option value="">-Please Select-</option>
                                  {managers.map((item) => {
                                    return (
                                      <option
                                        value={item.id}
                                        name={item.userName}
                                      >
                                        {item.userName}
                                      </option>
                                    );
                                  })}
                                </select>
                              </div>
                              <div className="form-group rht">
                                <Form_Button onClick={(e) => updateMangers()} />
                              </div>
                            </>
                          ) : (
                            ""
                          )}
                        </div>
                      </div>
                      <div className="col-6">
                        <a
                          className="clear-btn btn"
                          data-toggle="tooltip"
                          data-placement="top"
                          title="Clear Filters"
                          onClick={() => clearFilters()}
                        >
                          <i class="fa fa-trash" aria-hidden="true"></i>
                        </a>
                        {auth.userData.roleId <= 2 ? (
                          <a
                            className="clear-btn-success btn"
                            data-toggle="tooltip"
                            data-placement="top"
                            title="Auto Distribute Leads"
                            onClick={() => autoDistributeAgents()}
                          >
                            <i class="fa-solid fa-business-time"></i>
                          </a>
                        ) : (
                          ""
                        )}
                        {auth.userData.roleId <= 2 ? (
                          <div className="filter-box-1">
                            <label className="custom">Filter Agents</label>
                            {agents.map((item) => {
                              return (
                                <Form_Radio
                                  label={item.userName}
                                  id={item.id}
                                  value={item.id}
                                  name="filter-radio"
                                  onChange={(e) => getLeadsByAgent(e)}
                                />
                              );
                            })}
                          </div>
                        ) : (
                          ""
                        )}
                        <div className="filter-box-2">
                          <label className="custom">Filter Disposition</label>
                          {dispositions.map((item) => {
                            return (
                              <Form_Radio
                                label={item.name}
                                id={item.id}
                                value={item.id}
                                name="filter-radio"
                                onChange={(e) => getLeadsByDisposition(e)}
                              />
                            );
                          })}
                        </div>
                        {auth.userData.roleId <= 2 ? (
                          <div className="filter-box-3">
                            <label className="custom">Filter by Category</label>
                            <div className="check-list radio">
                              <input
                                type="radio"
                                id="distributed"
                                name="categoryRadio"
                              />
                              <label>Distributed</label>
                            </div>
                            <div className="check-list radio">
                              <input
                                type="radio"
                                id="undistributed"
                                name="categoryRadio"
                              />
                              <label>Undistriburted</label>
                            </div>
                          </div>
                        ) : (
                          ""
                        )}
                      </div>
                    </>
                  ) : (
                    ""
                  )}
                </div>
              </div>
            </div>
          ) : (
            ""
          )}

          <div className="form-box spaces lead-list-tabel">
            <div className="leads">
              <DataTable
                columns={columns}
                data={leads}
                noHeader
                defaultSortField="id"
                paginationRowsPerPageOptions={[5, 10, 25, 50, 100]}
                defaultSortAsc={false}
                pagination
                highlightOnHover
                selectableRows
                selectableRowsVisibleOnly
                onSelectedRowsChange={(e) => SelectItem(e)}
                conditionalRowStyles={conditionalRowStyles}
              />
            </div>
          </div>
        </div>
        {/* Modal */}
        {leadHistoryIdLength !== 0 && (
          <LeadsModal
            modalShow={modalShow}
            show={modalShow}
            lead={leadHistoryId}
            statusHistory={statusHistory}
            onHide={() => setModalShow(false)}
          />
        )}

        <LeadsCommentsModal
          modalShow={leadCommentModal}
          show={leadCommentModal}
          onHide={() => hideNotesModal()}
          onSave={() => updateLeadNotes()}
          //  Send API CALLED DATA USING PROPS
          oldNotes={notesHistory}
          handleUpdateNotes={handleUpdateNotes}
        />
      </div>
    </>
  );
};

export default ViewLeadsForm;
