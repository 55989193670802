import React from "react";

const Form_Input = (props) => {
  const {
    label,
    name,
    div_class,
    type,
    accept,
    id,
    onChange,
    value,
    condition,
    conditionValue,
    conditionValueClass,
    disabled,
    required = false,
  } = props;
  return (
    <div className={`${div_class} form-group`}>
      <label className="col-3">{label}</label>
      <input
        id={id}
        type={type}
        className={`${condition} col-3`}
        name={name}
        accept={accept}
        onChange={onChange}
        value={value}
        required={required}
        disabled={disabled}
        {...props}
      ></input>
      {conditionValue ? (
        <span className={conditionValueClass}>{conditionValue}</span>
      ) : (
        ""
      )}
    </div>
  );
};

export default Form_Input;
