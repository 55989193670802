import React, { useEffect, useState } from "react";
import Form_Button from "../../Custom-Components/Form_Button";
import Form_Input from "../../Custom-Components/Form_Input";
import Form_Select from "../../Custom-Components/Form_Select";
import Auto_Complete from "../../Custom-Components/Form_AutoComplete";
import Form_Grup_Input from "../../API/Checkl_Country";
import Simple_Input from "../../Custom-Components/Simple_Input";
import Form_Editor from "../../Custom-Components/Form_Editor";
import { apiurl, authCode } from "../../Host";
import { useSelector } from "react-redux";
import { Booking_API } from "../../API/Booking_API";
import { useNavigate } from "react-router";
import axios from "axios";
import moment from "moment";
const CreateBookingForm = () => {
  const navigate = useNavigate();
  const [booking, setBooking] = useState({
    address: "",
    amount: "",
    bookingDetails: "",
    bookingStatus: "",
    dob: "",
    email: "",
    givenName: "",
    lastName: "",
    paymentStatus: "",
    phone: "",
    productId: "",
    productName: "",
    userId: "",
    userName: "",
    zipCode: "",
    leadCode: "",
    leadId: "",
    bookingOption: "",
    bcc: "",
    cc: "",
    otherRes: "",
    mcoAmount: "",
    agentComment: "",
    alternateMobileNo: "",
  });

  const [emailStatus, setEmailStatus] = useState(false);
  const [emailText, setEmailText] = useState("");
  const [lead, setLead] = useState({});
  const [products, setProducts] = useState([]);
  const [selectedProduct, setSelectedProducts] = useState({});
  const [Error, setError] = useState({});
  const auth = useSelector((state) => state.auth);
  //  Document Upload Feature Start
  const [documentList, setDocumentList] = useState([
    {
      documentName: "",
      document: "",
    },
  ]);

  const handDocuments = (e, index) => {
    const { name, value } = e.target;
    const list = [...documentList];
    list[index][name] = value;
    setDocumentList(list);
  };
  //  Document Upload Feature End

  const addDocumentInputs = () => {
    setDocumentList([
      ...documentList,
      {
        documentName: "",
        document: "",
      },
    ]);
  };
  const paymentStatus = [
    { id: 1, name: "Success", value: "Success" },
    { id: 2, name: "Pending", value: "Pending" },
    { id: 3, name: "Failed", value: "Failed" },
  ];
  const bookingStatus = [
    { id: "Open", name: "Open", value: "Open" },
    { id: "Follow up", name: "Follow up", value: "Follow up" },
    {
      id: "Payment Follow up",
      name: "Payment Follow up",
      value: "Payment Follow up",
    },
    { id: "Closed", name: "Closed", value: "Closed" },
  ];

  const bookingOption = [
    { id: "Flight sales", name: "Flight Sales", value: "Flight Sales" },
    { id: "Hotel sales", name: "Hotel Sales", value: "Hotel Sales," },
    { id: "Packages", name: "Packages", value: "Packages" },
    { id: "Other", name: "Other", value: "Other" },
  ];
  const checkLead = (e) => {
    GetValue(e);
    var requestOptions = {
      method: "GET",
      redirect: "follow",
    };

    fetch(
      apiurl + "/getByEmail/" + e.target.value + "?authCode=" + authCode,
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        if (result.email != null) {
          setEmailText(
            result.email +
              " Already exist Customer " +
              result.name +
              " " +
              result.leadCode
          );
          setEmailStatus(true);
          setLead(result);
        } else {
          setEmailStatus(false);
          setEmailText(null);
        }
      })
      .catch((error) => {
        setEmailStatus(false);
        setEmailText(null);
      });
  };

  const getAllProducts = () => {
    var requestOptions = {
      method: "GET",
      redirect: "follow",
    };

    fetch(
      apiurl + "/getProductsBySiteId/1?authCode=" + authCode,
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        setProducts(result);
      })
      .catch((error) => console.log("error", error));
  };
  console.log("products", products);
  //***************************bieksh */
  const sendMailApiFunc = async (booking) => {
    const options = {
      method: "POST",
      url: `${apiurl}sendMailS`,
      headers: {
        cookie: "JSESSIONID=986D3D6210CD2E0E273A683AA7353E2F",
        "Content-Type": "application/json",
      },
      data: {
        recipient: booking.email,
        cc: booking.cc,
        bcc: booking.bcc,
        content: "",
        subject: "",
        msgBody: booking.bookingDetails,
      },
    };

    const data = await axios.request(options);
    console.log(data);
  };

  const SubmitBooking = (e) => {
    e.preventDefault();
    Booking_API(booking, lead, auth.userData, navigate, selectedProduct);
    setBooking({
      address: "",
      amount: "",
      bookingDetails: "",
      bookingStatus: "",
      dob: "",
      email: "",
      givenName: "",
      lastName: "",
      paymentStatus: "",
      phone: "",
      productId: "",
      productName: "",
      userId: "",
      userName: "",
      zipCode: "",
      leadCode: "",
      leadId: "",
      bookingOption: "",
      bcc: "",
      cc: "",
      otherRes: "",
      mcoAmount: "",
      agentComment: "",
      alternateMobileNo: "",
    });
  };
  const GetValue = (e) => {
    setBooking({ ...booking, [e.target.name]: e.target.value });
  };



  const GetContent = (e) => {
    setBooking({
      ...booking,
      [e.target.id]: e.target.getContent(),
    });
  };

  useEffect(() => {
    getAllProducts();
  }, []);


  return (
    <>
      <div className="form-box">
        <div className="container form-content">
          <h1 className="form-head">
            <i class="fa-solid page-icon fa-ticket"></i>&nbsp;Create Booking
          </h1>
          <div className="form-group">
            <form onSubmit={SubmitBooking}>
              <div className="row">
                <Form_Input
                  label="Given Name"
                  name="givenName"
                  div_class="col-3"
                  id="givenName"
                  value={booking.givenName}
                  type="text"
                  onChange={(e) => GetValue(e)}
                />
                <Form_Input
                  label="Last Name"
                  name="lastName"
                  div_class="col-3"
                  id="lastName"
                  value={booking.lastName}
                  type="text"
                  onChange={(e) => GetValue(e)}
                />
                <Form_Input
                  label="D.O.B"
                  name="dob"
                  div_class="col-3"
                  type="date"
                  id="dob"
                  max={moment().format("YYYY-MM-DD")}
                  value={booking.dob}
                  onChange={(e) => GetValue(e)}
                />

                <Form_Input
                  label="Customer Email"
                  name="email"
                  div_class="col-3"
                  id="email"
                  type="email"
                  value={booking.email}
                  pattern="^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$"
                  onChange={(e) => checkLead(e)}
                  condition={emailStatus ? "input-success" : "input-error"}
                  conditionValueClass={
                    emailStatus
                      ? "input-success-message"
                      : "input-error-message"
                  }
                  conditionValue={emailText}
                />

                <Form_Input
                  label="Phone"
                  name="phone"
                  div_class="col-3"
                  id="phone"
                  value={booking.phone}
                  type="tel"
                  onChange={(e) => GetValue(e)}
                />
                <Form_Input
                  label="Zip Code"
                  name="zipCode"
                  div_class="col-3"
                  id="zipCode"
                  value={booking.zipCode}
                  type="text"
                  onChange={(e) => GetValue(e)}
                />
                <Form_Input
                  label="Address"
                  name="address"
                  div_class="col-6"
                  id="address"
                  type="text"
                  value={booking.address}
                  onChange={(e) => GetValue(e)}
                />

                {/* <Form_Input
                  label="Email_Bcc"
                  name="bcc"
                  type="email"
                  div_class="col-3"
                  value={booking.bcc}
                  id="bcc"
                  pattern="^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$"
                  onChange={(e) => GetValue(e)}
                  conditionValue={Error.bcc}
                  conditionValueClass={"input-error-message"}
                /> */}
                {/* <Form_Input
                  label="Email_Cc"
                  name="cc"
                  div_class="col-3"
                  type="email"
                  id="cc"
                  pattern="^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$"
                  value={booking.cc}
                  onChange={(e) => GetValue(e)}
                  conditionValue={Error.cc}
                  conditionValueClass={"input-error-message"}
                /> */}

                <Auto_Complete
                  div_class="col-8"
                  type="text"
                  options={products}
                  label="Select Product Refrence"
                  setSelectedProducts={setSelectedProducts}
                />
                <Form_Input
                  label="Alternate Phone"
                  name="alternateMobileNo"
                  div_class="col-3"
                  id="alternateMobileNo"
                  value={booking.alternateMobileNo}
                  type="tel"
                  onChange={(e) => GetValue(e)}
                />

                <Form_Input
                  label="Total Booking Amount"
                  name="amount"
                  div_class="col-3"
                  id="amount"
                  type="text"
                  value={booking.amount}
                  onChange={(e) => GetValue(e)}
                />
                <Form_Input
                  label="MCO Amount"
                  name="mcoAmount"
                  div_class="col-3"
                  id="mcoAmount"
                  type="text"
                  value={booking.mcoAmount}
                  onChange={(e) => GetValue(e)}
                />

                <Form_Select
                  label="Booking Option"
                  dropdown={bookingOption}
                  id="bookingOption"
                  name="bookingOption"
                  divClass="col-3"
                  onChange={(e) => GetValue(e)}
                  required={false}
                />
                {booking.bookingOption === "Other" ? (
                  <Form_Input
                    label="Reasons For Other "
                    name="otherRes"
                    div_class="col-3"
                    id="otherRes"
                    type="text"
                    value={booking.otherRes}
                    onChange={(e) => GetValue(e)}
                  />
                ) : null}

                <Form_Select
                  label="Payment Status"
                  dropdown={paymentStatus}
                  id="paymentStatus"
                  name="paymentStatus"
                  divClass="col-3"
                  required={false}
                  onChange={(e) => GetValue(e)}
                />
                <Form_Select
                  label="Booking Status"
                  dropdown={bookingStatus}
                  id="bookingStatus"
                  name="bookingStatus"
                  divClass="col-3"
                  required={false}
                  onChange={(e) => GetValue(e)}
                />
                {/* <div className="d-flex justify-content-between my-2">
                  <div className=" d-flex flex-column w-full">
                    <Form_Select
                      label="Booking Option"
                      dropdown={bookingOption}
                      id="bookingOption"
                      name="bookingOption"
                      divClass="w-full"
                      onChange={(e) => GetValue(e)}
                      required={false}
                    />
                    {Error && (
                      <span className="error-message">
                        {Error.bookingOption}
                      </span>
                    )}
                  </div>

                  {booking.bookingOption === "other" ? (
                    <Form_Input
                      label="Reasons For Other "
                      name="otherRes"
                      div_class="col-3"
                      id="otherRes"
                      type="text"
                      value={booking.otherRes}
                      onChange={(e) => GetValue(e)}
                    />
                  ) : null}
                  <div className=" d-flex flex-column w-full">
                    <Form_Select
                      label="Payment Status"
                      dropdown={paymentStatus}
                      id="paymentStatus"
                      name="paymentStatus"
                      divClass="w-full"
                      required={false}
                      onChange={(e) => GetValue(e)}
                    />
                    {Error && (
                      <span className="error-message">
                        {Error.paymentStatus}
                      </span>
                    )}
                  </div>
                  <div className=" d-flex flex-column w-full">
                    <Form_Select
                      label="Booking Status"
                      dropdown={bookingStatus}
                      id="bookingStatus"
                      name="bookingStatus"
                      divClass="w-full"
                      required={false}
                      onChange={(e) => GetValue(e)}
                    />
                    {Error && (
                      <span className="error-message">
                        {Error.bookingStatus}
                      </span>
                    )}
                  </div>
                </div> */}

                <Form_Editor
                  label="Booking Details"
                  div_class="col-12"
                  label_class="col-6"
                  initaltext="<p><strong>Flights -</strong></p>
                  <p>&nbsp;</p>
                  <p><strong>Hotels -</strong></p>
                  <p>&nbsp;</p>
                  <p><strong>Trains -<br></strong></p>
                  <p>&nbsp;</p>
                  <p><strong>Car -</strong></p>
                  <p>&nbsp;</p>
                  <p><strong>Additional Information -</strong></p>
                  <p>&nbsp;</p>
                  <p>&nbsp;</p>"
                  id="bookingDetails"
                  onChange={(e) => GetContent(e)}
                />

                {/* <Form_Input
                  label="Agent Comment"
                  name="agentComment"
                  div_class="col-3"
                  type="text"
                  id="agentComment"
                  value={booking.agentComment}
                  onChange={(e) => GetValue(e)}
                /> */}
                <label className="mx-2 text-black mb-2">Agent Comment</label>
                <textarea
                  placeholder="Agent Comment"
                  name="agentComment"
                  id="agentComment"
                  value={booking.agentComment}
                  onChange={(e) => GetValue(e)}
                  className="mx-3 col-6"
                ></textarea>
                <Form_Button div_class=" submt" />

                {/* <button
                  onClick={() => SubmitBooking()}
                  className="col-3 submt undefined btn  text-white"
                  style={{ background: "#e13535" }}
                >
                  Sumbit
                </button> */}
              </div>
            </form>
            {/* <div className="d-flex">
              <Form_Button div_class="col-3 submt" value="Send Email" />
              <Form_Button div_class="col-3 submt" value="Download" />
            </div> */}
          </div>
        </div>
      </div>
      {/* sendMAil */}
      {/* <div className="form-box">
        <div className="container form-content">
          <div className="row">
            <form></form>
          </div>
        </div>
      </div> */}
      <div className="form-box">
        <div className="container form-content">
          <div className="row">
            {documentList.map((x, i) => (
              <>
                <div className="col-3">
                  <Form_Input
                    label="Document Name"
                    type="text"
                    value={x.documentName}
                    name="documentName"
                    onChange={(e) => handDocuments(e, i)}
                  />
                </div>
                <div className="col-3">
                  <Form_Input
                    label="Document"
                    type="file"
                    value={x.document}
                    name="document"
                    onChange={(e) => handDocuments(e, i)}
                  />
                </div>
              </>
            ))}
            <Form_Button
            // onClick={addDocumentInputs}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default CreateBookingForm;
