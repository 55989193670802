import React from 'react'
import TopBar from '../Atom/TopBar/TopBar'
import SideBar from "../Atom/SideBar/SideBar";
import AddProduct from "../Molecule/Product/AddProduct";


const Product = () => {
  return (
    <div>
      <TopBar />
      <SideBar/>
      <AddProduct/>
    </div>
  )
}

export default Product