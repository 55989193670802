import React, { useState, useEffect } from "react";
import { Table } from "react-bootstrap";
import { apiurl, authCode } from "../../Host";
import Form_Input from "../../Custom-Components/Form_Input";
import { useNavigate } from "react-router";
import LeadsCommentsModal from "./Modals/LeadsCommentsModal";
import { updateLeadNote } from "../../API/Lead_API";
import { useSelector } from "react-redux";
import moment from "moment";

const ViewProfile = (leadId) => {
  const navigate = useNavigate();
  const [bookings, setBookings] = useState([]);
  const [statusHistory, setStatusHistory] = useState([]);
  const [leadsNotes, setLeadNotes] = useState({});
  const [leadDetails, setLeadDetails] = useState({});
  const [paymentHistory,setPaymentHistory] = useState([]);
    const [leadCommentModal, setLeadCommentModal] = useState(false);
    const auth = useSelector((state) => state.auth);
  const getAllBookings = () => {
    var requestOptions = {
      method: "GET",
      redirect: "follow",
    };

    fetch(
      apiurl +
        "/getAllBookings/" +
        leadId.leadId.leadId +
        "?authCode=" +
        authCode,
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        setBookings(result);
      })
      .catch((error) => console.log("error", error));
  };
const showNotesModal=()=>{
  setLeadCommentModal(true);
  
}
 const hideNotesModal = () => {
   setLeadCommentModal(false);
 };
  const handleUpdateNotes = (e, name) => {
    e.preventDefault();
    setLeadNotes({ ...leadsNotes, [name]: e.target.value });
  };
  const getLeadStatusHistroy = () => {
    var requestOptions = {
      method: "GET",
      redirect: "follow",
    };

    fetch(
      apiurl +
        "/getLeadStatusHistroy/" +
        leadId.leadId.leadId +
        "?authCode=" +
        authCode,
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        setStatusHistory(result);
      })
      .catch((error) => console.log("error", error));
  };

  const getLeadNotes = () => {
    var requestOptions = {
      method: "GET",
      redirect: "follow",
    };

    fetch(
      apiurl +
        "/getLeadNotesHistory/" +
        leadId.leadId.leadId +
        "?authCode=" +
        authCode,
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        // console.log(result);
        setLeadNotes(result);
      })
      .catch((error) => console.log("error", error));
  };

  console.log("setLeadNotes", leadsNotes);
 
  const getLeadDeatails = () => {
    var requestOptions = {
      method: "GET",
      redirect: "follow",
    };

    fetch(
      apiurl +
        "/getLeadDetails/1/" +
        leadId.leadId.leadId +
        "?authCode=" +
        authCode,
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        setLeadDetails(result);
      })
      .catch((error) => console.log("error", error));
  };
  console.log("leadDetails", leadDetails);
   const updateLeadNotes = () => {
    setLeadCommentModal(false);
    updateLeadNote(leadsNotes, auth.userData, leadDetails);
  };
  const getLeadPaymentHistory = () => {
    var requestOptions = {
      method: "GET",
      redirect: "follow",
    };

    fetch(
      apiurl+"/getPaymentsByLeadId/"+leadId.leadId.leadId+"?authCode="+authCode,
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        console.log(result)
        setPaymentHistory(result)
      })
      .catch((error) => console.log("error", error));
  };
  const givePaymentStatus = (status) => {
    switch (status) {
      case "1":
        return "Success";
      case "2":
        return "Pending";
      case "3":
        return "Failed";
      default:
        return "Not Assigned";
    }
  };

  const giveBookingStatus = (status) => {
    switch (status) {
      case "1":
        return "Open";
      case "2":
        return "Closed";
      case "3":
        return "Completed";
      default:
        return "Not Assigned";
    }
  };
  const getCurrentStatus = (status) => {
    switch (status) {
      case 1:
        return "Sold";
      case 2:
        return "Call Back";
      case 3:
        return "Not Intrested";
      case 4:
        return "No Answer";
      case 5:
        return "Answering Machine";
      case 6:
        return "DND";
      case 7:
        return "Initial Mail";
      case 8:
        return "Contacted";
      case 9:
        return "Follow Up";
      case 10:
        return "Prospect";
      case 11:
        return "Invalid";
      default:
        return "Not Assigned";
    }
  };

  useEffect(() => {
    getAllBookings();
    getLeadStatusHistroy();
    getLeadNotes();
    getLeadDeatails();
    getLeadPaymentHistory();
  }, []);

  return (
    <>
      <div className="form-box">
        <div className="container form-content">
          <h2 className="form-head">
            <i class="fa-solid page-icon fa-user"></i>&nbsp;Customer Details
          </h2>
          <div className="row">
            <Form_Input
              label="Name"
              name="name"
              div_class="col-3"
              id="name"
              value={leadDetails.name}
            />
            <Form_Input
              label="Email"
              name="email"
              div_class="col-3"
              id="email"
              value={leadDetails.email}
            />
            <Form_Input
              label="Email"
              name="email"
              div_class="col-3"
              id="email"
              value={leadDetails.email}
            />
            <Form_Input
              label="Contact"
              name="contact"
              div_class="col-3"
              id="contact"
              value={leadDetails.contact}
            />
            <Form_Input
              label="Contact"
              name="contact"
              div_class="col-3"
              id="contact"
              value={leadDetails.contact}
            />
            <Form_Input
              label="Location"
              name="location"
              div_class="col-3"
              id="location"
              value={leadDetails.location}
            />
            <Form_Input
              label="Lead Code"
              name="leadCode"
              div_class="col-3"
              id="leadCode"
              value={leadDetails.leadCode}
            />
            <Form_Input
              label="Current Disposition"
              name="disposition"
              div_class="col-3"
              id="disposition"
              value={getCurrentStatus(leadDetails.dispositionId)}
            />
            <Form_Input
              label="Create Date"
              name="createDate"
              div_class="col-3"
              id="createDate"
              value={leadDetails.createDate}
            />
            <Form_Input
              label="Modify Date"
              div_class="col-3"
              name="modifyDate"
              id="modifyDate"
              value={leadDetails.modifyDate}
            />
            <Form_Input
              label="Manager Name"
              div_class="col-3"
              name="managerName"
              id="managerName"
              value={leadDetails.managerName}
            />
            <Form_Input
              label="Agent Name"
              div_class="col-3"
              name="agentName"
              id="agentName"
              value={leadDetails.agentName}
            />
            <Form_Input
              label="Facebook FormId"
              div_class="col-3"
              name="formId"
              id="formId"
              value={leadDetails.formId}
            />
            <Form_Input
              label="Facebook FormName"
              div_class="col-3"
              name="formId"
              id="formId"
              value={leadDetails.formName}
            />
            <Form_Input
              label="Source Media"
              div_class="col-3"
              name="sourceMedia"
              id="sourceMedia"
              value={leadDetails.source}
            />
          </div>
        </div>
      </div>
      {/* Booking Section */}
      <div className="form-box">
        <div className="container form-content">
          <h2 className="form-subHead">Booking History</h2>
          <Table responsive="sm">
            <thead>
              <tr>
                <th>#Databases Id</th>
                <th>Booking Id</th>
                <th>Booking Status</th>
                <th>Payment Status</th>
                {/* <th>User Id</th> */}
                <th>Create Date</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {bookings.map((item) => (
                <tr>
                  <td>{item.id}</td>
                  <td>{item.bookingId}</td>
                  <td>{giveBookingStatus(item.bookingStatus)}</td>
                  <td>{givePaymentStatus(item.paymentStatus)}</td>
                  <td>{item.createDate}</td>
                  <td>
                    <button
                      className="btn btn-warning"
                      onClick={() => navigate("/Booking/View/" + item.id + "")}
                    >
                      View
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </div>
      </div>
      <div className="form-box devider">
        <div className="container form-content">
          <div className="row">
            <div className="col">
              <h2 className="form-subHead">Lead Call Status History</h2>
              <table className="tabel table-bordered border-primary leadstatustable">
                <thead>
                  <tr>
                    <th>User Name</th>
                    <th>Disposition</th>
                    <th>Comment</th>
                    <th>Date</th>
                  </tr>
                </thead>
                <tbody>
                  {statusHistory.map((item, i) => (
                    <tr>
                      <td>{item.userName}</td>
                      <td>{item.disposition}</td>
                      <td>{item.comment}</td>
                      <td>{moment(item.createDate).format("DD MMM ,YYYY")}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
          <div className="row">
            <div className="col mt-3 lead-review">
              <h2 className="form-subHeads">Lead Notes</h2>
              <span className="led-edit">
                Last Edited By <b>{leadsNotes.userName}</b> on{" "}
                <b>{moment(leadsNotes.modifyDate).format("DD MMM ,YYYY")}</b>
              </span>
              <div className="led-user">{leadsNotes.notes}</div>
              <button
                className="btn btn-warning mt-2"
                onClick={() => {
                  showNotesModal();
                }}
              >
                {" "}
                Edit Comment
              </button>
            </div>
          </div>
        </div>
      </div>
      <LeadsCommentsModal
        modalShow={leadCommentModal}
        show={leadCommentModal}
        onHide={() => hideNotesModal()}
        onSave={() => updateLeadNotes()}
        //  Send API CALLED DATA USING PROPS
        oldNotes={leadsNotes}
        handleUpdateNotes={handleUpdateNotes}
      />
      {/* Payment Histroy Section */}
      <div className="form-box">
        <div className="container form-content">
          <h2 className="form-head">
            <i class="fa-solid page-icon fa-money-bill"></i>&nbsp;Payment
            History
          </h2>
          <Table responsive="sm">
            <thead>
              <tr>
                <th>Booking Id</th>
                <th>Amount</th>
                <th>Payment Mode</th>
                {/* <th>User Id</th> */}
                <th>Create Date</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {paymentHistory.map((item) => (
                <tr>
                  <td>{item.bookingId}</td>
                  <td>{item.amount}</td>
                  <td>{item.paymentMode}</td>
                  <td>{item.createDate}</td>
                  <td>
                    <button
                      className="btn btn-warning"
                      onClick={() => navigate("/Payments/Edit/" + item.id + "")}
                    >
                      View
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </div>
      </div>
    </>
  );
};

export default ViewProfile;
