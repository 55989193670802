import React from "react";
import PaymentBar from "../Molecule/PaymentDetail/PaymentBar";
import PaymentInfo from "../Molecule/PaymentDetail/PaymentInfo";

const PaymentDetail=()=>{
    return(
        <>
<PaymentBar/>
<PaymentInfo/>
        </>
    )
}

export default PaymentDetail;