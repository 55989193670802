import { apiurl, authCode } from "../../Host";
import React, { useEffect, useRef, useState } from "react";
import EditBooking from "./Components/EditBooking";
import Lottie from "react-lottie";
import Load from "../../Image/product-loader.json";

const EditBookingForm = ({ bookingId }) => {
  const [editBooking, setEditBooking] = useState({});
  const [Loader, setLoader] = useState(true);

  console.log("editBooking", editBooking);

  const getBookingDetails = () => {
    var requestOptions = {
      method: "GET",
      redirect: "follow",
    };

    fetch(
      apiurl +
        "/getBookingDetails/" +
        bookingId.bookingId +
        "?authCode=" +
        authCode,
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        console.log(result);
        setEditBooking(result);
        setLoader(false);
      })
      .catch((error) => {
        console.log("error", error);
        setLoader(true);
      });
  };
  console.log("editBooking", editBooking);
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: Load,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };
  useEffect(() => {
    getBookingDetails();
  }, []);

  return (
    <>
      {Loader === true ? (
        <div className="loader-lotti">
          <Lottie options={defaultOptions} height={250} width={250} />
        </div>
      ) : (
        <EditBooking editBookingData={editBooking} />
      )}
    </>
  );
  // <div className="form-box">
  //   <div className="container form-content">
  //     <h1 className="form-head">
  //       <i class="fa-solid page-icon fa-ticket"></i>&nbsp;Edit Booking{" "}
  //       <span className="form-head-product">{editBooking.bookingId}</span>
  //     </h1>
  //     <form onSubmit={SubmitEditBooking}>
  //       <div className="row">
  //         <Form_Input
  //           label="Given Name"
  //           name="givenName"
  //           div_class="col-3"
  //           id="givenName"
  //           value={editBooking.givenName}
  //           onChange={(e) => GetValue(e, "givenName")}
  //           disabled
  //         />
  //         <Form_Input
  //           label="Last Name"
  //           name="lastName"
  //           div_class="col-3"
  //           id="lastName"
  //           value={editBooking.lastName}
  //           onChange={(e) => GetValue(e, "lastName")}
  //           disabled
  //         />
  //         <Form_Input
  //           label="D.O.B"
  //           name="dob"
  //           div_class="col-3"
  //           id="dob"
  //           defaultValue={date}
  //           onChange={(e) => GetValue(e, "dob")}
  //           disabled
  //         />
  //         <Form_Input
  //           label="Customer Email"
  //           name="email"
  //           div_class="col-3"
  //           id="email"
  //           value={editBooking.email}
  //           required={true}
  //           onChange={(e) => GetValue(e, "email")}
  //           disabled
  //         />
  //         <Form_Input
  //           label="Phone"
  //           name="phone"
  //           div_class="col-3"
  //           id="phone"
  //           type="tel"
  //           value={editBooking.phone}
  //           onChange={(e) => GetValue(e, "phone")}
  //           disabled
  //         />
  //         <Form_Input
  //           label="Alternate Phone No"
  //           name="alternateMobileNo"
  //           div_class="col-3"
  //           id="alternateMobileNo"
  //           type="tel"
  //           value={editBooking.alternateMobileNo}
  //           onChange={(e) => GetValue(e, "alternateMobileNo")}
  //         />
  //         <Form_Input
  //           label="Zip Code"
  //           name="zipCode"
  //           div_class="col-3"
  //           id="zipCode"
  //           value={editBooking.zipCode}
  //           onChange={(e) => GetValue(e, "zipCode")}
  //           disabled
  //         />
  //         <Form_Input
  //           label="Address"
  //           name="address"
  //           div_class="col-6"
  //           id="address"
  //           value={editBooking.address}
  //           onChange={(e) => GetValue(e, "address")}
  //           disabled
  //         />

  //         {/* <Auto_Complete
  //             div_class="col-6"
  //             // options={products}
  //             label="Select Product Refrence"
  //             // setSelectedProducts={setSelectedProducts}
  //             /> */}

  //         <Form_Editor
  //           label="Booking Details"
  //           div_class="col-12"
  //           label_class="col-6"
  //           initaltext={editBooking.bookingDetails}
  //           id="bookingDetails"
  //           onChange={(e) => GetContent(e)}
  //         />

  //         <Form_Select
  //           label="Payment Status"
  //           dropdown={paymentStatus}
  //           id="paymentStatus"
  //           name="paymentStatus"
  //           divClass="col-3 form-group"
  //           value={editBooking.paymentStatus}
  //           onChange={(e) => GetValue(e, "paymentStatus")}
  //         />

  //         <Form_Select
  //           label="Booking Status"
  //           dropdown={bookingStatus}
  //           id="bookingStatus"
  //           name="bookingStatus"
  //           divClass="col-3 form-group"
  //           value={editBooking.bookingStatus}
  //           onChange={(e) => GetValue(e, "bookingStatus")}
  //         />

  //         <Form_Input
  //           label="Total Amount"
  //           name="amount"
  //           div_class="col-3"
  //           id="amount"
  //           value={editBooking.amount}
  //           onChange={(e) => GetValue(e, "amount")}
  //         />
  //         <Form_Input
  //           label="MCO Amount"
  //           name="mcoAmount"
  //           div_class="col-3"
  //           id="mcoAmount"
  //           value={editBooking.mcoAmount}
  //           onChange={(e) => GetValue(e, "mcoAmount")}
  //         />
  //         {/* <Form_Input
  //           label="Agent Comment"
  //           name="agentComment"
  //           div_class="col-3 h-100"
  //           id="agentComment"
  //           value={editBooking.agentComment}
  //           onChange={(e) => GetValue(e, "agentComment")}
  //         /> */}
  //         <label className="mx-2 text-black mb-2">Agent Comment</label>
  //         <textarea
  //           placeholder="Agent Comment"
  //           name="agentComment"
  //           id="agentComment"
  //           value={editBooking.agentComment}
  //           onChange={(e) => GetValue(e, "agentComment")}
  //           className="mx-3 col-6"
  //         ></textarea>
  //         <Form_Button div_class="col-3 submt" />
  //       </div>
  //     </form>
  //     {authUser.userData.roleId === 1 || authUser.userData.roleId === 3 ? (
  //       <button
  //         className="undefined btn  btn-success mt-4 col-3 h-50"
  //         onClick={downloadBookingdetails}
  //       >
  //         Download Booking Details
  //       </button>
  //     ) : (
  //       ""
  //     )}
  //   </div>
  // </div>
};

export default EditBookingForm;
