import React from "react";
import Form_Input from "../../Custom-Components/Form_Input";
import Form_Grup_Input from "../../API/Check_Location";
import Form_Select from "../../Custom-Components/Form_Select";
import Form_Button from "../../Custom-Components/Form_Button";
import State, { useEffect, useState } from "react";
import { apiurl, authCode } from "../../Host";
import { Location_API } from "../../API/Location_API";
import Form_Editor from "../../Custom-Components/Form_Editor";
import { sites, status } from "../../StaticData";
import { uploadImage } from "../../API/Country_API";
import swal from "sweetalert";

function AddLocationForm() {
  const [locationValues, setLocationValues] = useState({
    locationName: "",
    metaDescription: "",
    metaTittle: "",
    metaKeywords: "",
    locationDescription: "",
    siteId: "",
    countryId: "",
    packageId: "",
    vaccationTypeId: "",
    status: "",
  });
  const [getter, setter] = useState("Check Availability");
  const [countryImage, setCountryImage] = useState(null);
  const [countries, setCountries] = useState([]);

  const GetValue = (e) => {
    setLocationValues({ ...locationValues, [e.target.name]: e.target.value });
  };

  const GetContent = (e) => {
    setLocationValues({
      ...locationValues,
      [e.target.id]: e.target.getContent(),
    });
  };

  const getCountries = () => {
    var requestOptions = {
      method: "GET",
      redirect: "follow",
    };

    fetch(
      apiurl +
        `/getCountriesBySiteIdCRM/${locationValues.siteId}?authCode=` +
        authCode,
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        setCountries(result);
      })
      .catch((error) => console.log("error", error));
  };

  const [packages, setPackages] = useState([]);

  const getPackages = () => {
    var requestOptions = {
      method: "GET",
      redirect: "follow",
    };

    fetch(
      apiurl +
        `/getPackagesBySiteIdCRM/${locationValues.siteId}?authCode=` +
        authCode,
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        setPackages(result);
      })
      .catch((error) => console.log("error", error));
  };

  const [vaccationType, setvaccationType] = useState([]);

  const getVaccationType = () => {
    var requestOptions = {
      method: "GET",
      redirect: "follow",
    };

    fetch(
      apiurl +
        `/getVaccationsTypeBySiteIdCRM/${locationValues.siteId}?authCode=` +
        authCode,
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        setvaccationType(result);
      })
      .catch((error) => console.log("error", error));
  };

  const SubmitCountry = async (e) => {
    e.preventDefault();
    const data = await uploadImage(countryImage);
    if (data) {
      Location_API(locationValues, data.data);
    } else {
      console.log("error");
      swal("Alert!", "Something Went Wrong", "error");
    }
  };

  const masterFunction = () => {
    getCountries();
    getPackages();
    getVaccationType();
  };

  return (
    <div className="form-box">
      <div className="container form-content">
        <h1 className="form-head">
          <i class="fa-solid page-icon fa-map-location-dot"></i>&nbsp;Add
          Locations
        </h1>
        <form onSubmit={SubmitCountry}>
          <div className="row">
            <Form_Select
              label="Select Site"
              name="siteId"
              dropdown={sites}
              id="siteId"
              value={locationValues.siteId}
              onChange={(e) => GetValue(e)}
              onClick={() => masterFunction()}
            />
            <Form_Select
              label="Select Country"
              name="countryId"
              dropdown={countries}
              id="countryId"
              value={locationValues.countryId}
              onChange={(e) => GetValue(e)}
            />
            <Form_Select
              label="Select Package"
              name="packageId"
              dropdown={packages}
              id="packageId"
              value={locationValues.packageId}
              onChange={(e) => GetValue(e)}
            />
            <Form_Select
              label="Select Vaccation Type"
              name="vaccationTypeId"
              dropdown={vaccationType}
              id="vaccationTypeId"
              value={locationValues.vaccationTypeId}
              onChange={(e) => GetValue(e)}
            />
            <Form_Select
              label="Status"
              dropdown={status}
              id="status"
              name="status"
              value={locationValues.status}
              onChange={(e) => GetValue(e)}
            />
            <Form_Input
              label="Meta Description"
              name="metaDescription"
              div_class="col-6"
              id="metaDescription"
              value={locationValues.metaDescription}
              onChange={(e) => GetValue(e)}
            />
            <Form_Input
              label="Meta Tittle"
              name="metaTittle"
              div_class="col-6"
              id="metaTittle"
              value={locationValues.metaTittle}
              onChange={(e) => GetValue(e)}
            />
            <Form_Input
              label="Meta Keywords"
              name="metaKeywords"
              div_class="col-6"
              id="metaKeywords"
              value={locationValues.metaKeywords}
              onChange={(e) => GetValue(e)}
            />
            <Form_Input
              type="file"
              label="Country Image"
              name="countryImage"
              div_class="col-6"
              onChange={(e) => setCountryImage(e.target.files[0])}
            />
            <Form_Grup_Input
              label="Location Name"
              name="locationName"
              div_class="col-6"
              id="locationName"
              value={locationValues.locationName}
              onChange={(e) => GetValue(e)}
              getter={getter}
              setter={setter}
              siteId={locationValues.siteId}
            />
            <Form_Editor
              label="Location Long Description / Itinary"
              div_class="col-12"
              label_class="col-6"
              onChange={(e) => GetContent(e)}
              initaltext=""
              id="locationDescription"
            />

            <Form_Button />
          </div>
        </form>
      </div>
    </div>
  );
}

export default AddLocationForm;
